import { apiUrl } from './settings';
import create from 'zustand';

export const useStore = create((set, get) => ({
  language: 'nl',
  searchOpen: false,
  initiatives: [],
  mixedContents: [],
  loading: true,
  fetchInitiatives: async () => {
    const response = await fetch(`${apiUrl}/initiatives?_limit=-1`);
    const res = await response.json();
    set((state) => ({
      initiatives: res,
      mixedContents: [...state.mixedContents, res],
    }));
  },
  experts: [],
  fetchExperts: async () => {
    const response = await fetch(`${apiUrl}/experts?_limit=-1`);
    const res = await response.json();
    set((state) => ({
      experts: res,
      mixedContents: [...state.mixedContents, res],
    }));
  },
  actors: [],
  fetchActors: async () => {
    const response = await fetch(`${apiUrl}/stakeholders?_limit=-1`);
    const res = await response.json();
    set((state) => ({
      actors: res,
      mixedContents: [...state.mixedContents, res],
    }));
  },
  indicators: [],
  fetchIndicators: async () => {
    const response = await fetch(`${apiUrl}/indicators?_limit=-1`);
    const res = await response.json();
    set({
      indicators: res,
    });
  },
  getIndicator: (id) => {
    const indicatorsArray = get().indicators;
    return indicatorsArray.filter((v) => {
      return v.id === id;
    });
  },
}));

export const getInitiative = (id) => {
  return useStore.getState().initiatives.filter((v) => v.id === id);
};

export const getActor = (id) => {
  return useStore.getState().actors.filter((v) => v.id === id);
};

export const getExpert = (id) => {
  return useStore.getState().experts.filter((v) => v.id === id);
};

export const getGroupedIndicators = (id, groupId) => {
  var group = useStore
    .getState()
    .indicators.filter((v) => {
      return v.group_id === groupId;
    })
    .sort((v) => {
      return v.id === id ? -1 : 1;
    });
  return group;
};

export const getFilteredIndicators = (subsetKey, subsetValue) => {
  return useStore.getState().indicators.filter((v) => {
    return v[subsetKey] === subsetValue;
  });
};

export const getIndicator = (id) => {
  return useStore.getState().indicators.filter((v) => {
    console.log(v.id, id);
    return v.id === id;
  });
};

export const getIndicatorStakeholders = (indicator) => {
  const final = [];
  if (indicator.stakeholders) {
    final.push(indicator.stakeholders);
  }
  if (indicator.initiatives) {
    final.push(indicator.initiatives);
  }
  if (indicator.experts) {
    final.push(indicator.experts);
  }

  return final;
};
