import React, { useState, useEffect } from 'react';
import LayoutPage from './LayoutPage';
import { flatten } from 'lodash';
import {
  getLogoIfPresent,
  getFormattedTruthyMultipleSelect,
  labelWrapper,
  deSluggify,
  addhttp,
  cleanUrlText,
} from '../utils';
import { useStore } from '../store';
import noImg from '../assets/images/no-img.png';
import Loading from '../components/Loading';
import { apiUrl } from '../settings';
import { useQuery } from 'react-query';
import ExpertCard from '../components/ExpertCard';
import ActorCard from '../components/ActorCard';

function Initiative({ id }) {
  const language = useStore((state) => state.language);
  const { isLoading, error, data } = useQuery(`initiative${id}`, () =>
    fetch(`${apiUrl}/initiatives/${id}`).then((res) => res.json()),
  );

  if (isLoading) return <Loading />;

  if (error) return 'An error has occurred: ' + error.message;

  const initiative = data;
  initiative.actors = initiative.stakeholders;
  const p = getLogoIfPresent(initiative);
  const imageUrl = p ? apiUrl + p : noImg;
  const imgStyle = { backgroundImage: 'url(' + imageUrl + ')' };
  console.log('initiative', initiative);

  const interests = getFormattedTruthyMultipleSelect(initiative, 'interests');
  const categories = getFormattedTruthyMultipleSelect(initiative, 'category');
  const targetb2b = getFormattedTruthyMultipleSelect(
    initiative.target,
    'target_b2b',
  );
  const targetb2c = getFormattedTruthyMultipleSelect(
    initiative.target,
    'target_b2c',
  );
  const target = flatten(targetb2b, targetb2c);

  return (
    <LayoutPage>
      <div className="stakeholder-page initiative">
        {initiative && (
          <section className="py-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h2 className="mb-1 mt-space">{initiative.name}</h2>
                  <h5 className="color-c3 font-strong-m mt-2 pb-2">
                    {labelWrapper['initiative'][language] + ' — '}
                    {initiative.infos && (
                      <a
                        href={addhttp(initiative.infos)}
                        target="_blank"
                        className="external color-c3"
                      >
                        {cleanUrlText(initiative.infos)}
                      </a>
                    )}
                  </h5>
                </div>
              </div>
            </div>
          </section>
        )}

        <section className="bg-white py-4 pb-5">
          {initiative && (
            <div className="container-fluid">
              <div className="row content">
                <div className="col-12 my-4 d-xl-none">
                  <div className="round-img" style={imgStyle}></div>
                </div>

                <div className="col-md-8 col-lg-12 col-xl-10 two-cols-lg">
                  <div className="break-inside-avoid">
                    <div className="font-sans-s color-black-50 mt-4">
                      {labelWrapper['description'][language]}
                    </div>
                    <div className="font-serif-m mt-1 mr-sm-5">
                      {language === 'en'
                        ? initiative.description_en
                        : initiative.description}
                    </div>
                  </div>

                  {categories.length > 0 && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['category'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        {categories.map((category, j) => {
                          const categoryName = labelWrapper[category][language];
                          return (
                            <span key={j}>
                              {j > 0 ? ', ' : ''}
                              {categoryName}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {initiative.interests && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['interests'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        {interests.map((interest, j) => {
                          const interestName =
                            labelWrapper['interestslist'][interest][language];
                          return (
                            <span key={j}>
                              {j > 0 ? ', ' : ''}
                              {interestName}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {initiative.target && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['target'][language]}
                      </div>

                      <div className="font-serif-m mt-1">
                        B2b : {initiative.target.b2b ? 'true' : 'false'}
                      </div>

                      <div className="font-serif-m mt-1">
                        B2c : {initiative.target.b2c ? 'true' : 'false'}
                      </div>

                      <div className="font-serif-m mt-1">
                        {target.map((t, j) => {
                          console.log(t);
                          const targetName = labelWrapper[t][language];
                          return (
                            <span key={j}>
                              {j > 0 ? ', ' : ''}
                              {targetName}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {initiative.price && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['price'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        <div>{initiative.price}</div>
                      </div>
                    </div>
                  )}

                  {initiative.capacity && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['capacity'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        <div>{initiative.capacity}</div>
                      </div>
                    </div>
                  )}

                  {initiative.registration && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['registration'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        <div>{initiative.registration}</div>
                      </div>
                    </div>
                  )}

                  {initiative.infos && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['infos'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        <div>{initiative.infos}</div>
                      </div>
                    </div>
                  )}

                  {initiative.funding && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['funding'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        <div>{initiative.funding}</div>
                      </div>
                    </div>
                  )}

                  {initiative.contacts.length > 0 &&
                    (initiative.contacts[0].contact_email ||
                      initiative.contacts[0].contact_tel ||
                      initiative.contacts[0].contact_name) && (
                      <div className="break-inside-avoid mr-xl-5 pt-1">
                        <div className="font-sans-s color-black-50 mt-4">
                          {labelWrapper['contacts'][language]}
                        </div>
                        <div className="font-serif-m mt-1">
                          {initiative.contacts.map((contact, o) => {
                            return (
                              <div key={o}>
                                {contact.contact_email && (
                                  <div>{contact.contact_email}</div>
                                )}
                                {contact.contact_name && (
                                  <div>{contact.contact_name}</div>
                                )}
                                {contact.contact_tel && (
                                  <div>{contact.contact_tel}</div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                  {initiative.location && initiative.location.address && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['location'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        <div>{initiative.location.address}</div>
                      </div>
                    </div>
                  )}

                  {(initiative.start_date ||
                    initiative.start_date_hour ||
                    initiative.end_date ||
                    initiative.end_date_hour) && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['event_date'][language]}
                      </div>

                      {initiative.start_date && (
                        <div className="font-serif-m mt-1">
                          {labelWrapper['start_date'][language]} :{' '}
                          {initiative.start_date}
                        </div>
                      )}
                      {initiative.start_date_hour && (
                        <div className="font-serif-m mt-1">
                          {labelWrapper['start_date_hour'][language]} :{' '}
                          {initiative.start_date_hour}
                        </div>
                      )}
                      {initiative.end_date && (
                        <div className="font-serif-m mt-1">
                          {labelWrapper['end_date'][language]} :{' '}
                          {initiative.end_date}
                        </div>
                      )}
                      {initiative.end_date_hour && (
                        <div className="font-serif-m mt-1">
                          {labelWrapper['end_date_hour'][language]} :{' '}
                          {initiative.end_date_hour}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="col-md-2 my-4 d-none d-xl-flex justify-content-end">
                  <div className="round-img" style={imgStyle}></div>
                </div>
              </div>
            </div>
          )}
        </section>

        {(initiative.experts.length > 0 || initiative.actors.length > 0) && (
          <section className="stakeholders-cards py-4 pb-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h2 className="mt-space-2 mb-space">
                    {labelWrapper['related'][language]}
                  </h2>
                </div>
              </div>
              <div className="row">
                {initiative.experts.map((d, i) => {
                  if (d) {
                    return <ExpertCard d={d} key={i} />;
                  }
                })}
                {initiative.actors.map((d, i) => {
                  if (d) {
                    return <ActorCard d={d} key={i} />;
                  }
                })}
              </div>
            </div>
          </section>
        )}
      </div>
    </LayoutPage>
  );
}

export default Initiative;
