import React, { useState, useEffect } from 'react';
import { LinePath, AreaClosed } from '@visx/shape';
import { extent } from 'd3-array';
import { flatten } from 'lodash';
import * as allCurves from '@visx/curve';
import { useStore } from '../store';
import { scaleTime, scaleLinear } from '@visx/scale';
import { AxisLeft, AxisBottom } from '@visx/axis';

const TrendCompared = ({ group, w = 200, indicator, setHover, hover }) => {
  const language = useStore((state) => state.language);
  const groupedData = flatten(group.map((d) => d.dataset));
  const dates = extent(groupedData, (d) => d.year);
  const bounds = extent(groupedData, (d) => d.data);
  const marginTop = 15;
  const marginLeft = 20;

  const svgWidth = w * 1.1 * group.length + marginLeft * 2 + marginLeft;
  const svgHeight = w / 1.25;

  const height = svgHeight - marginTop * 2;
  const width = w - marginLeft * 2;
  const xScale = scaleTime({
    range: [0, width],
  });
  const yScale = scaleLinear({
    range: [height, 0],
  });
  const yMax = height;
  xScale.domain([new Date(`01/01/${dates[0]}`), new Date(`01/01/${dates[1]}`)]);
  yScale.domain([0, bounds[1]]);

  return (
    <div>
      <svg height={svgHeight} width={svgWidth}>
        {group.map((g, index) => {
          const data = g.dataset;
          const dLast = data && data.length && data[data.length - 1];
          const subdates = extent(data, (d) => d.year);
          const selected = Number(indicator) === g.id;
          const subXScale = scaleTime({
            range: [0, width],
            domain: [
              new Date(`01/01/${subdates[0]}`),
              new Date(`01/01/${subdates[1]}`),
            ],
          });
          return (
            <g
              key={index}
              transform={`translate(${
                marginLeft * index + w * index + marginLeft * 2
              },${marginTop})`}
            >
              <g>
                {data && (
                  <AreaClosed
                    curve={allCurves['curveLinear']}
                    data={data}
                    x={(d) => {
                      return subXScale(new Date(d.year));
                    }}
                    y={(d) => {
                      return yScale(Number(d.data)) ?? yMax;
                    }}
                    yScale={yScale}
                    shapeRendering="geometricPrecision"
                    fill="#E9ECEF"
                  />
                )}
                {data && (
                  <LinePath
                    curve={allCurves['curveLinear']}
                    data={data}
                    x={(d) => {
                      return subXScale(new Date(d.year));
                    }}
                    y={(d) => {
                      return yScale(Number(d.data)) ?? yMax;
                    }}
                    stroke={selected ? '#4725F5' : '#b3b6b9'}
                    strokeWidth={2}
                    strokeOpacity={1}
                    shapeRendering="geometricPrecision"
                  />
                )}
                {data.map((d, j) => {
                  const isLast = j === data.length - 1;
                  const isHovered = d === hover;
                  d.indicator = g;
                  return (
                    <circle
                      cx={subXScale(new Date(d.year))}
                      cy={yScale(Number(d.data)) ?? yMax}
                      r={isLast ? 4 : 3}
                      fill={isHovered ? 'black' : isLast ? '#4725F5' : 'white'}
                      stroke={
                        isHovered
                          ? 'transparent'
                          : !isLast
                          ? '#4725F5'
                          : 'transparent'
                      }
                      strokeWidth={2}
                      onMouseEnter={() => setHover(d)}
                      onMouseLeave={() => setHover(null)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  );
                })}
                {/* <circle
                  cx={subXScale(new Date(dLast.year))}
                  cy={yScale(Number(dLast.data)) ?? yMax}
                  r="3"
                  fill={selected ? '#4725F5' : '#b3b6b9'}
                /> */}
              </g>
              <g transform={`translate(${0}, ${height + marginTop})`}>
                <text
                  dx={subXScale(new Date(`01/01/${subdates[0]}`))}
                  dy={0}
                  fontSize={10}
                  textAnchor="middle"
                  fill={'rgba(0,0,0,.5)'}
                >
                  {subdates[0]}
                </text>
                <text
                  dx={subXScale(new Date(`01/01/${subdates[1]}`))}
                  dy={0}
                  fontSize={10}
                  textAnchor="middle"
                  fill={'rgba(0,0,0,.5)'}
                >
                  {subdates[1]}
                </text>
              </g>
              <g
                transform={`translate(${0}, ${0})`}
                style={{ pointerEvents: 'none' }}
              >
                <AxisBottom
                  top={0}
                  left={0}
                  scale={xScale}
                  numTicks={data.length - 1}
                  tickStroke={'rgba(0,0,0,.2)'}
                  tickClassName={'tickTrend'}
                  hideAxisLine={true}
                  hideTicks={false}
                  tickLength={height - 2}
                  tickComponent={({ formattedValue, ...tickProps }) => (
                    <text {...tickProps}>{formattedValue}</text>
                  )}
                  tickLabelProps={(value, index) => ({
                    fill: 'rgba(0,0,0,0)',
                    textAnchor: 'middle',
                    fontSize: '10px',
                    dx: '0px',
                    dy: '2px',
                  })}
                ></AxisBottom>
              </g>
            </g>
          );
        })}

        <g transform={`translate(${0}, ${marginTop})`}>
          <line
            x1={marginLeft * 2}
            x2={svgWidth}
            y1={svgHeight - marginTop * 2}
            y2={svgHeight - marginTop * 2}
            stroke={'rgba(0,0,0,.20)'}
            strokeDasharray={'4 2'}
          ></line>
          <line
            x1={marginLeft * 2}
            x2={svgWidth}
            y1={yScale(bounds[1])}
            y2={yScale(bounds[1])}
            stroke={'rgba(0,0,0,.20)'}
            strokeDasharray={'4 2'}
          ></line>
          <text
            dx={marginLeft * 2 - 10}
            dy={svgHeight - marginTop * 2}
            fontSize="10"
            fill={'rgba(0,0,0,.5)'}
            textAnchor="end"
          >
            0
          </text>
          <text
            dx={marginLeft * 2 - 10}
            dy={yScale(bounds[1]) + 5}
            fontSize="10"
            fill={'rgba(0,0,0,.5)'}
            textAnchor="end"
          >
            {bounds[1]}
          </text>
        </g>
      </svg>
    </div>
  );
};

export default TrendCompared;
