import React from 'react';
import { useStore } from '../store';
import { labelWrapper, navigateScrollTop } from '../utils';
import logos from '../assets/images/logos.png';

function Footer() {
  const language = useStore((state) => state.language);

  return (
    <footer id="footer">
      <div className="container-fluid">
        <div className="row py-5">
          <div className="col-12">
            <p className="color-white font-strong-ml">Eco Food Map</p>
            <p className="color-white font-serif-l">Leuven</p>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-xl-4 col-md-6">
            <p className="color-white-50 font-sans-s">
              {labelWrapper['indicators'][language]}
            </p>
            <p
              className="color-white font-serif-m my-1 cursor-pointer"
              onClick={() =>
                navigateScrollTop(
                  `/indicators/indicator_category/social_sustainability`,
                )
              }
            >
              {
                labelWrapper['indicatorCategories']['social_sustainability'][
                  language
                ]
              }
            </p>
            <p
              className="color-white font-serif-m my-1 cursor-pointer"
              onClick={() =>
                navigateScrollTop(
                  `/indicators/indicator_category/economic_sustainability`,
                )
              }
            >
              {
                labelWrapper['indicatorCategories']['economic_sustainability'][
                  language
                ]
              }
            </p>
            <p
              className="color-white font-serif-m my-1 cursor-pointer"
              onClick={() =>
                navigateScrollTop(
                  `/indicators/indicator_category/urban_rural_integration`,
                )
              }
            >
              {
                labelWrapper['indicatorCategories']['urban_rural_integration'][
                  language
                ]
              }
            </p>

            <p
              className="color-white font-serif-m my-1 cursor-pointer"
              onClick={() =>
                navigateScrollTop(
                  `/indicators/indicator_category/environmental_sustainability`,
                )
              }
            >
              {
                labelWrapper['indicatorCategories'][
                  'environmental_sustainability'
                ][language]
              }
            </p>
            <p
              className="color-white font-serif-m my-1 cursor-pointer"
              onClick={() =>
                navigateScrollTop(
                  `/indicators/indicator_category/food_governance`,
                )
              }
            >
              {labelWrapper['indicatorCategories']['food_governance'][language]}
            </p>
            <p className="color-white-50 font-sans-s my-4"></p>
          </div>
          <div className="col-xl-4 col-md-6">
            <p className="color-white-50 font-sans-s">
              {labelWrapper['stakeholder_ecosystem'][language]}
            </p>
            <p
              className="color-white font-serif-m my-1 cursor-pointer"
              onClick={() => navigateScrollTop(`/actors`)}
            >
              {labelWrapper['actors'][language]}
            </p>
            <p
              className="color-white font-serif-m my-1 cursor-pointer"
              onClick={() => navigateScrollTop(`/experts`)}
            >
              {labelWrapper['experts'][language]}
            </p>
            <p
              className="color-white font-serif-m my-1 cursor-pointer"
              onClick={() => navigateScrollTop(`/initiatives`)}
            >
              {labelWrapper['initiatives'][language]}
            </p>
            <p className="color-white-50 font-sans-s my-4"></p>
            <p className="color-white-50 font-sans-s">Information</p>
            <p
              className="color-white font-serif-m my-1 cursor-pointer"
              onClick={() => navigateScrollTop(`/about`)}
            >
              {labelWrapper['about'][language]}
            </p>
            {/* <p className="color-white font-serif-m my-1">Colophon</p> */}
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-xl-4 col-md-6 mt-2 d-flex align-items-center">
            <p className="color-white-50 font-sans-s">
              The EcoFoodMap has been supported by
            </p>
          </div>
          <div className="col-xl-5 col-md-6 mt-2 d-flex align-items-center position-relative">
            <img className="logos" src={logos} />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
