import Cards from '../components/Cards';
import IndicatorsWrapper from '../components/IndicatorsWrapper';
import Statistics from '../components/Statistics';
import Layout from './Layout';
import { useStore } from '../store';
import map from '../assets/images/map.png';
import emptyCard from '../assets/images/empty-card.svg';
import {
  // randomIntroSentence,
  navigateScrollTop,
  labelWrapper,
  isMobileWithTablet,
  breakpointIs,
} from '../utils';

// import ReactMarkdown from 'react-markdown';
import RandomSentence from '../components/RandomSentence';

import { useState, useMemo } from 'react';
import GeoMap from '../components/GeoMap';
import SystemMap from '../components/SystemMap';

function Home() {
  const language = useStore((state) => state.language);
  const [mapType, setMapType] = useState('geo');
  const [mapFilter, setFilter] = useState(null);
  const [categorySelected, setCategorySelected] = useState('experts');
  // const introSentence = randomIntroSentence(language);
  const imgStyle = { backgroundImage: 'url(' + emptyCard + ')' };

  const seed = useMemo(() => {
    return Math.random();
  }, []);

  return (
    <Layout>
      <div className="Home">
        <section className="intro-sentence">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="font-strong-xl upper mb-space-2">
                  <RandomSentence seed={seed} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="geographic-context">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-4 d-none d-xl-block">
                <img className="img-fluid" src={map} />
              </div>
              <div className="col-xl-8">
                <Statistics />

                {language === 'nl' ? (
                  <p className="color-white font-serif-l">
                    De <span className="font-strong-ml">Eco Food Map</span> is
                    een interactieve tool die het voedselsysteem in en rond
                    Leuven in kaart brengt. De tool geeft de nodige informatie
                    over alle initiatieven, actoren, experten en indicatoren in
                    het Leuvense voedselsysteem.
                  </p>
                ) : (
                  <p className="color-white font-serif-l">
                    The <span className="font-strong-ml">Eco Food Map</span> is
                    an interactive tool that maps the food system in and around
                    Leuven. The tool provides the necessary information about
                    all initiatives, actors, experts and indicators in the
                    Leuven food system.
                  </p>
                )}

                <p className="my-space-2">
                  <a
                    className="button l white"
                    onClick={() => {
                      navigateScrollTop(`/about`);
                    }}
                  >
                    {labelWrapper['learn_more'][language]}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="cards-wrapper pad">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h2 className="">
                  {labelWrapper['indicators_dashboard'][language]}
                </h2>
              </div>
            </div>
          </div>
          <IndicatorsWrapper />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 my-space-2 text-center">
                <a
                  className="button l my-space"
                  onClick={() => {
                    navigateScrollTop(`/indicators`);
                  }}
                >
                  {labelWrapper['see_all'][language]}
                </a>
              </div>
            </div>
          </div>
        </section>

        {isMobileWithTablet && (
          <div className="ui">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 d-flex align-items-center mb-5">
                  <h2 className="mb-0">
                    {labelWrapper['mapping_food_system'][language]}
                  </h2>
                </div>
                <div className="col-lg-6 d-flex align-items-center justify-content-lg-end mb-4">
                  <a
                    role="button"
                    className={`button selector ${
                      mapType === 'geo' ? 'active' : ''
                    }`}
                    onClick={() => setMapType('geo')}
                  >
                    {labelWrapper['geo_map'][language]}
                  </a>
                  <a
                    role="button"
                    className={`button selector ${
                      mapType === 'system' ? 'active' : ''
                    }`}
                    onClick={() => setMapType('system')}
                  >
                    {labelWrapper['sys_map'][language]}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        <section className="mapping">
          {mapType === 'geo' && (
            <div
              style={{
                width: isMobileWithTablet ? '10%' : '15%',
                top: isMobileWithTablet ? 10 : 100,
                left: isMobileWithTablet ? 10 : 30,
                position: 'absolute',
                zIndex: 3,
              }}
            >
              <svg>
                <circle style={{ fill: '#5100ff' }} cx="10" cy="10" r="5" />
                <circle style={{ fill: '#ff9c50' }} cx="10" cy="40" r="5" />
                <circle style={{ fill: '#ff6fa7' }} cx="10" cy="70" r="5" />
                <text className={`selectable-filter${mapFilter === 'actors' ? ' selected' : ''}`}
                  x="30"
                  y="15"
                  onClick={() =>
                    setFilter((state) =>
                      state === 'actors' ? null : 'actors',
                    )
                  }
                >
                  {labelWrapper['actors'][language]}
                </text>
                <text className={`selectable-filter${mapFilter === 'initiatives' ? ' selected' : ''}`}
                  x="30"
                  y="45"
                  onClick={() =>
                    setFilter((state) =>
                      state === 'initiatives' ? null : 'initiatives',
                    )
                  }
                >
                  {labelWrapper['initiatives'][language]}
                </text>
                <text className={`selectable-filter${mapFilter === 'experts' ? ' selected' : ''}`}
                  x="30"
                  y="75"
                  onClick={() =>
                    setFilter((state) =>
                      state === 'experts' ? null : 'experts',
                    )
                  }
                >
                  {labelWrapper['experts'][language]}
                </text>

                <text className="label explanation"
                  x="30"
                  y="105"
                >
                  {labelWrapper['explanation'][language]}
                </text>
              </svg>
            </div>
          )}
          {!isMobileWithTablet && (
            <div className="ui pad-small pad-small-b">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 d-flex align-items-center mb-5">
                    <h2 className="color-white mb-0">
                      {labelWrapper['mapping_food_system'][language]}
                    </h2>
                  </div>
                  <div className="col-lg-6 d-flex align-items-center justify-content-lg-end mb-5">
                    <a
                      role="button"
                      className={`button white selector ${
                        mapType === 'geo' ? 'active' : ''
                      }`}
                      onClick={() => setMapType('geo')}
                    >
                      {labelWrapper['geo_map'][language]}
                    </a>
                    <a
                      role="button"
                      className={`button white selector ${
                        mapType === 'system' ? 'active' : ''
                      }`}
                      onClick={() => setMapType('system')}
                    >
                      {labelWrapper['sys_map'][language]}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="mapping-viz-wrapper" style={{ minHeight: '200px' }}>
            {mapType === 'geo' && <GeoMap mapFilter={mapFilter} />}
            {mapType === 'system' && <SystemMap />}
          </div>
        </section>

        <section className="stakeholders-cards pad">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center mb-5">
                <h2 className="mb-0">
                  {labelWrapper['food_ecosystem'][language]}
                </h2>
              </div>
              <div className="col-lg-6 d-flex align-items-center justify-content-lg-end mb-5">
                <div>
                  <a
                    role="button"
                    className={`button selector ${
                      categorySelected === 'experts' ? 'active' : ''
                    }`}
                    onClick={() => setCategorySelected('experts')}
                  >
                    {labelWrapper['experts'][language]}
                  </a>
                  <a
                    role="button"
                    className={`button selector ${
                      categorySelected === 'actors' ? 'active' : ''
                    }`}
                    onClick={() => setCategorySelected('actors')}
                  >
                    {labelWrapper['actors'][language]}
                  </a>
                  <a
                    role="button"
                    className={`button selector ${
                      categorySelected === 'initiatives' ? 'active' : ''
                    }`}
                    onClick={() => setCategorySelected('initiatives')}
                  >
                    {labelWrapper['initiatives'][language]}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Cards selected={categorySelected} />
        </section>

        <section className="bg-purple pad-small">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-xl-4 pb-5 mb-2">
                <p className="font-serif-l color-white">
                  {language === 'en'
                    ? 'Are you a stakeholder of the Leuven food system?'
                    : ''}
                  <a
                    className="font-strong-ml hover-white hover-u d-block mt-space"
                    onClick={() => {
                      navigateScrollTop(`/form`);
                    }}
                  >
                    {language === 'en'
                      ? 'Submit your profile ›'
                      : 'Dien uw profiel in ›'}
                  </a>
                </p>
              </div>
              <div className="col-md-6 col-xl-4 offset-xl-4">
                <div className="empty-card" style={imgStyle}></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Home;
