import React, { useMemo, useState, useEffect } from 'react';
import { useStore } from '../store';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';
import { navigate } from '@reach/router';
import { getFormattedTruthyMultipleSelect, labelWrapper } from '../utils';
import ReactMarkdown from 'react-markdown';

const highlightedMdString = (search, string) => {
  const re = new RegExp('(' + search + ')', 'ig');
  return string.replace(re, '*$1*');
};

const getSuggestionValue = (suggestion) => suggestion.name;

const renderSuggestion = (suggestion, searchInput, language) => {
  // console.log("-------------------");
  // console.log("searchInput", searchInput);
  // console.log('suggestion', suggestion);
  // console.log("-------------------");
  const description = suggestion.description;
  const match_type = suggestion.match_type; // name, description, interests

  let line1;
  if (match_type === 'name') {
    line1 = (
      <ReactMarkdown className="suggestion-with-highlights">
        {highlightedMdString(searchInput, suggestion.name)}
      </ReactMarkdown>
    );
  } else {
    line1 = <span>{suggestion.name}</span>;
  }

  const extractAndHighlight = (search, commaSeparatedString) => {
    const re = new RegExp(search, 'i');
    const values = commaSeparatedString.split(',');
    const passedValues = values.filter((v) => re.test(v));
    const passedString = passedValues.map((v) => '**' + v + '**').join(', ');
    return highlightedMdString(search, passedString);
  };

  console.log(suggestion.type);

  let line2Md = labelWrapper[suggestion.type][language];
  if (match_type === 'description') {
    const re = new RegExp(searchInput, 'i');
    const words = suggestion.description.split(' ');
    const index = words.findIndex((w) => re.test(w));
    const surroundByN = 3;
    let stringStart = '… ';
    let stringEnd = ' …';
    let indexFrom = index - surroundByN;
    let indexTo = index + surroundByN + 1;
    if (indexFrom <= 0) {
      indexFrom = 0;
      stringStart = '';
    }
    if (indexTo >= words.length - 1) {
      indexTo = words.length - 1;
      stringEnd = '';
    }
    const excerpt =
      stringStart + words.slice(indexFrom, indexTo).join(' ') + stringEnd;

    line2Md += ' — ' + highlightedMdString(searchInput, excerpt);
  } else if (match_type === 'interests') {
    line2Md +=
      ` — ${labelWrapper['interests'][language]} ${labelWrapper['include'][language]} ` +
      extractAndHighlight(searchInput, suggestion.interests);
  } else if (match_type === 'activities') {
    line2Md +=
      ` — ${labelWrapper['activities'][language]} ${labelWrapper['include'][language]} ` +
      extractAndHighlight(searchInput, suggestion.activities);
  } else if (match_type === 'products') {
    line2Md +=
      ` — ${labelWrapper['products'][language]} ${labelWrapper['include'][language]} ` +
      extractAndHighlight(searchInput, suggestion.products);
  } else if (match_type === 'category') {
    line2Md +=
      ` — ${labelWrapper['categories'][language]}: ` +
      extractAndHighlight(searchInput, suggestion.category);
  }

  const line2 = (
    <ReactMarkdown
      className="suggestion-with-highlights"
      allowedTypes={['emphasis', 'strong', 'text', 'paragraph']}
    >
      {line2Md}
    </ReactMarkdown>
  );

  return (
    <div className="suggestion">
      <div className={'line-1 font-sans-m'}>{line1}</div>
      <div className={'line-2 font-sans-s type-' + suggestion.type}>
        {line2}
      </div>
    </div>
  );
};

function Searchbar() {
  const language = useStore((state) => state.language);
  const setLanguage = (language) => {
    useStore.setState({ language: language });
  };
  const searchOpen = useStore((state) => state.searchOpen);
  const setSearchOpen = (searchOpen) => {
    useStore.setState({ searchOpen: searchOpen });
  };
  const experts = useStore((state) => state.experts);
  const actors = useStore((state) => state.actors);
  const initiatives = useStore((state) => state.initiatives);
  const indicators = useStore((state) => state.indicators);

  const getInitiatives = useStore((state) => state.fetchInitiatives);
  const getExperts = useStore((state) => state.fetchExperts);
  const getActors = useStore((state) => state.fetchActors);
  const getIndicators = useStore((state) => state.fetchIndicators);

  useEffect(() => {
    if (initiatives.length === 0) {
      getInitiatives();
    }
    if (experts.length === 0) {
      getExperts();
    }
    if (actors.length === 0) {
      getActors();
    }
    if (indicators.length === 0) {
      getIndicators();
    }
  }, []);

  const [value, setValue] = useState('');
  const [results, setResults] = useState([]);

  const source = useMemo(() => {
    const thisItems = [];

    experts.forEach((d, i) => {
      thisItems.push({
        type: 'experts',
        name: d.name,
        description: language === 'en' ? d.description_en : d.description,
        id: d.id,
        key: 'expert' + d.id,
        activities: '',
        products: '',
        category: '',
        interests:
          d.interests &&
          getFormattedTruthyMultipleSelect(d, 'interests')
            .map((v) => labelWrapper['interestslist'][v][language])
            .join(','),
        match_type: null,
      });
    });
    actors.forEach((d, i) => {
      thisItems.push({
        type: 'actors',
        name: d.name,
        description: language === 'en' ? d.description_en : d.description,
        id: d.id,
        key: 'actor' + d.id,
        tags: '',
        category: '',
        interests:
          d.interests &&
          getFormattedTruthyMultipleSelect(d, 'interests')
            .map((v) => labelWrapper['interestslist'][v][language])
            .join(','),
        activities:
          d.activities &&
          getFormattedTruthyMultipleSelect(d, 'activities')
            .map((v) => labelWrapper[v][language])
            .join(','),
        products:
          d.products &&
          getFormattedTruthyMultipleSelect(d, 'products')
            .map((v) => labelWrapper[v][language])
            .join(','),
        match_type: null,
      });
    });
    initiatives.forEach((d, i) => {
      thisItems.push({
        type: 'initiatives',
        name: d.name,
        description: language === 'en' ? d.description_en : d.description,
        id: d.id,
        key: 'initiative' + d.id,
        activities: '',
        interests:
          d.interests &&
          getFormattedTruthyMultipleSelect(d, 'interests')
            .map((v) => labelWrapper['interestslist'][v][language])
            .join(','),
        category:
          d.category &&
          getFormattedTruthyMultipleSelect(d, 'category')
            .map((v) => labelWrapper[v][language])
            .join(','),
        products: '',
        match_type: null,
      });
    });

    indicators.forEach((d, i) => {
      thisItems.push({
        type: 'indicators',
        name: language === 'en' ? d.indicator_en : d.indicator_nl,
        description: '',
        id: d.id,
        key: 'indicator' + d.id,
        activities: '',
        interests: '',
        category: '',
        products: '',
        match_type: null,
      });
    });
    setResults(thisItems);
    return thisItems;
  }, [experts, initiatives, actors]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const re = new RegExp(_.escapeRegExp(value), 'i');
    const isMatch = (result) => {
      let matchType = null;

      const matchName = re.test(result.name);
      const matchDescripition = re.test(result.description);
      const matchActivity = re.test(result.activities);
      const matchProducts = re.test(result.products);
      const matchInterests = re.test(result.interests);
      const matchCategory = re.test(result.category);

      if (matchName) {
        matchType = 'name';
      } else if (matchDescripition) {
        matchType = 'description';
      } else if (matchActivity) {
        matchType = 'activities';
      } else if (matchProducts) {
        matchType = 'products';
      } else if (matchInterests) {
        matchType = 'interests';
      } else if (matchCategory) {
        matchType = 'category';
      }

      result.match_type = matchType;

      return (
        matchName ||
        matchDescripition ||
        matchActivity ||
        matchProducts ||
        matchInterests ||
        matchCategory
      );
    };

    return inputLength === 0 ? [] : source.filter(isMatch);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setResults(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setResults([]);
  };

  const inputProps = {
    placeholder:
      language == 'en' ? 'Search stakeholders…' : 'Zoek belanghebbenden…',
    value,
    onChange: (event, { newValue }) => {
      setValue(newValue);
    },
  };

  return (
    <div className="bg-grey-lll">
      <Autosuggest
        suggestions={results}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={(suggestion) => {
          return renderSuggestion(suggestion, value, language);
        }}
        shouldRenderSuggestions={(value) => value.trim().length >= 3}
        inputProps={inputProps}
        onSuggestionSelected={(e, val) => {
          setSearchOpen(false);
          navigate(`/${val.suggestion.type}/${val.suggestion.id}`);
        }}
      />
    </div>
  );
}

export default Searchbar;
