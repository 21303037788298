import React from 'react';
import { useStore } from '../store';
import { shuffle } from 'lodash';
import IndicatorCard from './IndicatorCard';

const IndicatorsWrapper = () => {
  const indicators = useStore((state) => state.indicators);

  return (
    <div className="container-fluid">
      <div className="row indicator-cards-container">
        {
          //shuffle(indicators)
          indicators
            .filter((d) => d.dataset[0] && d.dataset[0].data)
            .filter((g) => {
              return (
                g.geographic_area === 'municipality_leuven' ||
                g.geographic_area === 'arrondissement_leuven'
              );
            })
            .slice(0, 6)
            .map((d, i) => {
              return <IndicatorCard d={d} key={i} />;
            })
        }
      </div>
    </div>
  );
};

export default IndicatorsWrapper;
