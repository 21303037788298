import React from 'react';
import { apiUrl } from '../settings';
import {
  getFormattedTruthyMultipleSelect,
  getLogoIfPresent,
  labelWrapper,
} from '../utils';
import { useStore } from '../store';
import style from '../styles/Card.module.css';
import noImg from '../assets/images/no-img.png';
import { navigateScrollTop } from '../utils';

const ActorCard = ({ d }) => {
  const language = useStore((state) => state.language);
  const interests =
    d && d.interests ? getFormattedTruthyMultipleSelect(d, 'interests') : [];
  const maxWords = 22;
  const translated = language === 'en' ? d.description_en : d.description;
  const words = translated ? translated.split(' ') : [];
  let description;
  if (words.length == 0) {
    description = 'No description.';
  } else if (words.length <= maxWords) {
    description = translated;
  } else {
    description = words.splice(0, maxWords).join(' ') + '…';
  }
  const p = getLogoIfPresent(d);
  const imageUrl = p ? apiUrl + p : noImg;
  const imgStyle = { backgroundImage: 'url(' + imageUrl + ')' };

  return (
    <div className="col-md-6 col-xl-4 card-stakeholder-col">
      <div
        className="card-stakeholder"
        onClick={() => navigateScrollTop(`/actors/${d.id}`)}
      >
        <header>
          <div className="left">
            <div className="font-sans-s font-700 color-c2">
              {labelWrapper['actor'][language].toUpperCase()}
            </div>
            <div className="card-title">{d.name}</div>
            <div className="font-sans-s color-blackish-80">
              {interests.map((interest, j) => {
                const interestName =
                  labelWrapper['interestslist'][interest][language];
                const max = 3;
                if (j == 0) {
                  return <span key={j}>{interestName}</span>;
                } else if (j > 0 && j < max) {
                  return <span key={j}>, {interestName}</span>;
                } else if (j == max) {
                  return (
                    <span key={j}>
                      ,{' '}
                      {interests.length -
                        max +
                        ' ' +
                        labelWrapper['more'][language]}
                    </span>
                  );
                } else {
                  return false;
                }
              })}
            </div>
          </div>
          <div className="right">
            <div className="round-img thumb" style={imgStyle}></div>
          </div>
        </header>

        <div className="card-body">
          <div className="font-serif-m font-400">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default ActorCard;
