import React, { useMemo } from 'react';
import { useState, useEffect } from 'react';
import { openMenuMobile, labelWrapper } from '../utils';
import LinkScrollTop from '../components/LinkScrollTop';
import Searchbar from '../components/Searchbar';
import hamburger from '../assets/images/menu-hmb.svg';
import { useStore } from '../store';
import searchIcon from '../assets/images/search-purple.svg';

function Header() {
  const language = useStore((state) => state.language);
  const setLanguage = (language) => {
    useStore.setState({ language: language });
  };

  const searchOpen = useStore((state) => state.searchOpen);
  const setSearchOpen = (searchOpen) => {
    useStore.setState({ searchOpen: searchOpen });
  };

  if (searchOpen) {
    document.body.classList.add("search-open");
  } else {
    document.body.classList.remove("search-open");
  }
  
  useEffect(() => {
    if (searchOpen) {
      var input = document.querySelector("input.react-autosuggest__input");
      input.focus();
    }
  });

  return (
    <nav id="header">
      <div className="container-fluid overflow-visible">
        <div className="row">
          <div className="col-12 wrapper">

            <div className={`left ${searchOpen ? " search" : ""}`}>

              <LinkScrollTop className="font-strong-m color-c2" to={'/'}>
                Eco Food Map{' '}
                <span className="font-serif-m header-serif">Leuven</span>
              </LinkScrollTop>
            </div>

            { !searchOpen &&
              <div className="right-mobile">
                <span className="mr-4" onClick={() => setSearchOpen(true)}>
                  <a>
                    <img src={searchIcon} />
                  </a>
                </span>
                <a
                  onClick={() => {
                    openMenuMobile();
                  }}
                >
                  <img src={hamburger} />
                </a>
              </div>
            }

            { !searchOpen &&
              <div className="right">
                <LinkScrollTop
                  to={'/indicators'}
                  getProps={({ isCurrent }) => {
                    return {
                      className: isCurrent ? 'active item' : 'item',
                    };
                  }}
                >
                  {labelWrapper['indicators'][language]}
                </LinkScrollTop>

                <LinkScrollTop
                  to={'/experts'}
                  getProps={({ isCurrent }) => {
                    return {
                      className: isCurrent ? 'active item' : 'item',
                    };
                  }}
                >
                  {labelWrapper['experts'][language]}
                </LinkScrollTop>

                <LinkScrollTop
                  to={'/initiatives'}
                  getProps={({ isCurrent }) => {
                    return {
                      className: isCurrent ? 'active item' : 'item',
                    };
                  }}
                >
                  {labelWrapper['initiatives'][language]}
                </LinkScrollTop>

                <LinkScrollTop
                  to={'/actors'}
                  getProps={({ isCurrent }) => {
                    return {
                      className: isCurrent ? 'active item' : 'item',
                    };
                  }}
                >
                  {labelWrapper['actors'][language]}
                </LinkScrollTop>

                <LinkScrollTop
                  to={'/about'}
                  getProps={({ isCurrent }) => {
                    return {
                      className: isCurrent ? 'active item' : 'item',
                    };
                  }}
                >
                  {labelWrapper['about'][language]}
                </LinkScrollTop>

                <span className="mx-4 pl-2">
                  <a
                    className={`header-language-el ${language === 'en' ? 'active' : ''}`}
                    onClick={() => setLanguage('en')}
                  >EN</a>
                  {" · "}
                  <a
                    className={`header-language-el ${language === 'nl' ? 'active' : ''}`}
                    onClick={() => setLanguage('nl')}
                  >NL</a>
                </span>

                <span onClick={() => setSearchOpen(true)}>
                  <a>
                    <img src={searchIcon} />
                  </a>
                </span>

              </div>
            }

            { searchOpen &&
              <div className="general-search">
                <img src={searchIcon} />
                {/*<input type="text" placeholder="Search stakeholders…" />*/}
                <div className="search-field-wrapper">
                  <Searchbar />
                </div>
                <a className="close" onClick={() => setSearchOpen(false)}>&times;</a>
              </div>
            }

          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
