import React, { useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../settings';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { labelWrapper, deSluggify } from '../utils';

import Switch from '@material-ui/core/Switch';

const FormInitiative = ({ language }) => {
  const { register, errors, handleSubmit } = useForm();
  const [formCompiled, setFormCompiled] = useState(false);
  const [interestError, setInterestError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [state, setState] = useState({
    faird_trade: false,
    social_inclusion: false,
    organic: false,
    sustainable_technologies: false,
    community_shared_agriculture: false,
    legislation_policy: false,
    animal_based: false,
    biodiversity: false,
    short_supply: false,
    food_safety: false,
    logistics: false,
    waste_disposal: false,
    health: false,
    plant_based: false,
    cultivation_technique: false,
  });

  const [categoryState, setCategoryState] = useState({
    event: false,
    awareness_campaign: false,
    pilot_study_project: false,
    education_training: false,
    network_platform: false,
    citizens_initiative: false,
  });

  const [targetState, setTargetState] = useState({
    b2c: false,
    b2b: false,
  });

  const [targetb2cState, setTargetb2cState] = useState({
    babies: false,
    children: false,
    youth: false,
    students: false,
    elder: false,
    low_income_households: false,
    disabled_people: false,
  });

  const [targetb2bState, setTargetb2bState] = useState({
    farms_food_production: false,
    retailers: false,
    horeca: false,
    distribution: false,
    schools: false,
  });

  const onSubmit = (data) => {
    console.log(data);

    data.status = 'draft';

    data.interests = state;
    const hasInterestTrue = Object.keys(state).some((k) => state[k]);
    if (hasInterestTrue) {
      setInterestError(false);
    } else {
      setInterestError(true);
      return;
    }
    data.capacity = data.capacity || null;

    data.category = categoryState;
    const hasCategoryTrue = Object.keys(categoryState).some(
      (k) => categoryState[k],
    );
    if (hasCategoryTrue) {
      setCategoryError(false);
    } else {
      setCategoryError(true);
      return;
    }

    data.target = {
      ...targetState,
      target_b2b: { ...targetb2bState },
      target_b2c: { ...targetb2cState },
    };
    data.contacts = [
      {
        contact_email: data.contact_email,
        contact_name: data.contact_name,
        contact_tel: data.contact_tel,
      },
    ];
    data.location = {
      address: data.address,
      latitude: data.latitude || null,
      longitude: data.longitude || null,
    };
    data.published_at = null;

    console.log(data);

    axios
      .post(`${apiUrl}/initiatives`, data)
      .then((res) => {
        console.log(res.data);
        setFormCompiled(true);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleCategoryChange = (event) => {
    console.log(event.target.name, event.target.checked);
    setCategoryState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleTargetChange = (event) => {
    setTargetState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleTargetb2bChange = (event) => {
    setTargetb2bState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleTargetb2cChange = (event) => {
    setTargetb2cState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <div>
      <section className="bg-purple pb-4 mb-space-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h2
                className="color-white text-center font-serif-l"
                style={{ maxWidth: '600px', margin: '0 auto' }}
              >
                {labelWrapper['application_initiative'][language]}
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section id="form-wrapper">
        <div className="container-fluid narrow">
          <div className="row">
            {!formCompiled ? (
              <div className="col-12">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <TextField
                    id="outlined-basic"
                    label={labelWrapper['name'][language]}
                    variant="outlined"
                    className="formInputText"
                    name="name"
                    inputProps={{
                      name: 'name',
                      ref: register({ required: true }),
                    }}
                  />
                  {errors.name && 'name is required'}
                  <TextField
                    id="outlined-basic"
                    label={labelWrapper['description'][language]}
                    variant="outlined"
                    className="formInputText"
                    inputProps={{
                      name: 'description',
                      ref: register({ required: true }),
                    }}
                  />
                  {errors.description && 'description is required'}

                  <TextField
                    id="outlined-basic"
                    label={labelWrapper['infos'][language]}
                    variant="outlined"
                    inputProps={{
                      name: 'infos',
                      ref: register({ required: true }),
                    }}
                  />
                  {errors.infos && 'infos is required'}

                  <div className="row">
                    <div className="col-sm-6">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['registration'][language]}
                        variant="outlined"
                        inputProps={{
                          name: 'registration',
                          ref: register(),
                        }}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['price'][language]}
                        variant="outlined"
                        inputProps={{
                          name: 'price',
                          ref: register(),
                        }}
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 col-sm-3">
                      <TextField
                        id="date"
                        label={labelWrapper['start_date'][language]}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          name: 'start_date',
                          ref: register(),
                        }}
                      />
                    </div>
                    <div className="col-6 col-sm-3">
                      <TextField
                        id="date"
                        label={labelWrapper['start_date_hour'][language]}
                        type="time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300,
                          name: 'start_date_hour',
                          ref: register(),
                        }}
                      />
                    </div>
                    <div className="col-6 col-sm-3">
                      <TextField
                        id="date"
                        label={labelWrapper['end_date'][language]}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          name: 'end_date',
                          ref: register(),
                        }}
                      />
                    </div>
                    <div className="col-6 col-sm-3">
                      <TextField
                        id="date"
                        label={labelWrapper['end_date_hour'][language]}
                        type="time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300,
                          name: 'end_date_hour',
                          ref: register(),
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['funding'][language]}
                        variant="outlined"
                        inputProps={{
                          name: 'funding',
                          ref: register(),
                        }}
                        style={{ width: '100%' }}
                      />
                      {errors.funding && 'funding is required'}
                    </div>
                    <div className="col-sm-6">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['capacity'][language]}
                        variant="outlined"
                        type="number"
                        inputProps={{
                          name: 'capacity',
                          ref: register(),
                        }}
                        style={{ width: '100%' }}
                      />
                      {errors.capacity && 'capacity is required'}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['location_address'][language]}
                        variant="outlined"
                        inputProps={{
                          name: 'address',
                          ref: register(),
                        }}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="col-6 col-sm-3">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['latitude'][language]}
                        variant="outlined"
                        inputProps={{
                          name: 'latitude',
                          ref: register(),
                        }}
                      />
                    </div>
                    <div className="col-6 col-sm-3">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['longitude'][language]}
                        variant="outlined"
                        inputProps={{
                          name: 'longitude',
                          ref: register(),
                        }}
                      />
                    </div>
                  </div>

                  <FormGroup>
                    <TextField
                      id="outlined-basic"
                      label={labelWrapper['email_contact'][language]}
                      variant="outlined"
                      inputProps={{
                        name: 'contact_email',
                        ref: register({ required: true }),
                      }}
                    />
                    <TextField
                      id="outlined-basic"
                      label={labelWrapper['name_contact'][language]}
                      variant="outlined"
                      inputProps={{
                        name: 'contact_name',
                        ref: register(),
                      }}
                    />
                    <TextField
                      id="outlined-basic"
                      label={labelWrapper['telephone_contact'][language]}
                      variant="outlined"
                      inputProps={{
                        name: 'contact_tel',
                        ref: register(),
                      }}
                    />
                  </FormGroup>

                  <div className="row mt-4">
                    <div className="col-sm-6">
                      <FormLabel component="legend">
                        {labelWrapper['interests'][language]}
                      </FormLabel>
                      <FormGroup>
                        {Object.keys(state)
                          .slice(0, 8)
                          .map((interest, key) => {
                            return (
                              <FormControlLabel
                                key={interest}
                                control={
                                  <Switch
                                    checked={state[interest]}
                                    onChange={handleChange}
                                    name={interest}
                                  />
                                }
                                label={
                                  labelWrapper['interestslist'][interest][
                                    language
                                  ]
                                }
                              />
                            );
                          })}
                      </FormGroup>
                    </div>
                    <div className="col-sm-6">
                      <FormGroup>
                        {Object.keys(state)
                          .slice(8, 16)
                          .map((interest, key) => {
                            return (
                              <FormControlLabel
                                key={interest}
                                control={
                                  <Switch
                                    checked={state[interest]}
                                    onChange={handleChange}
                                    name={interest}
                                  />
                                }
                                label={
                                  labelWrapper['interestslist'][interest][
                                    language
                                  ]
                                }
                              />
                            );
                          })}
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-sm-6">
                      <FormLabel component="legend">
                        {labelWrapper['category'][language]}
                      </FormLabel>
                      <FormGroup>
                        {Object.keys(categoryState).map((category, key) => {
                          return (
                            <FormControlLabel
                              key={category}
                              control={
                                <Switch
                                  checked={categoryState[category]}
                                  onChange={handleCategoryChange}
                                  name={category}
                                />
                              }
                              label={labelWrapper[category][language]}
                            />
                          );
                        })}
                      </FormGroup>
                    </div>
                    <div className="col-sm-6"></div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-6">
                      <FormLabel component="legend">Target</FormLabel>
                      <FormGroup>
                        {Object.keys(targetState).map((target, key) => {
                          return (
                            <FormControlLabel
                              key={target}
                              control={
                                <Switch
                                  checked={targetState[target]}
                                  onChange={handleTargetChange}
                                  name={target}
                                />
                              }
                              label={target}
                            />
                          );
                        })}
                      </FormGroup>
                    </div>
                    <div className="col-sm-6"></div>
                  </div>
                  <div className="row mt-4">
                    {targetState.b2c && (
                      <div className="col-sm-6">
                        <FormGroup>
                          <FormLabel component="legend">Target b2c</FormLabel>
                          {Object.keys(targetb2cState).map((target, key) => {
                            return (
                              <FormControlLabel
                                key={target}
                                control={
                                  <Switch
                                    checked={targetb2cState[target]}
                                    onChange={handleTargetb2cChange}
                                    name={target}
                                  />
                                }
                                label={labelWrapper[target][language]}
                              />
                            );
                          })}
                        </FormGroup>
                      </div>
                    )}
                    {targetState.b2b && (
                      <div className="col-sm-6">
                        <FormGroup>
                          <FormLabel component="legend">Target b2b</FormLabel>

                          {Object.keys(targetb2bState).map((target, key) => {
                            return (
                              <FormControlLabel
                                key={target}
                                control={
                                  <Switch
                                    checked={targetb2bState[target]}
                                    onChange={handleTargetb2bChange}
                                    name={target}
                                  />
                                }
                                label={labelWrapper[target][language]}
                              />
                            );
                          })}
                        </FormGroup>
                      </div>
                    )}
                  </div>

                  {errors.name && (
                    <div style={{ color: 'red' }}>Mame is required</div>
                  )}
                  {errors.description && (
                    <div style={{ color: 'red' }}>Description is required</div>
                  )}
                  {errors.infos && (
                    <div style={{ color: 'red' }}>Websitte is required</div>
                  )}
                  {errors.email_contact && (
                    <div style={{ color: 'red' }}>Email is required</div>
                  )}
                  {interestError && (
                    <div style={{ color: 'red' }}>
                      At least one interest is required
                    </div>
                  )}
                  {categoryError && (
                    <div style={{ color: 'red' }}>
                      At least one category is required
                    </div>
                  )}

                  <input
                    type="submit"
                    value={labelWrapper['submit'][language]}
                    className="submitButton"
                  />
                </form>
              </div>
            ) : (
              <div className="col-12">
                <h2>
                  Request sent! our team will process your request as soon as
                  possible.
                </h2>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormInitiative;
