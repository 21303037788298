// import Statistics from '../components/Statistics';
import Layout from './Layout';
import map from '../assets/images/map.png';
import img1 from '../assets/images/about-s-1.jpg';
import img2 from '../assets/images/about-s-2.jpg';
import ReactMarkdown from 'react-markdown';
import { navigateScrollTop, labelWrapper } from '../utils';
import React, { Fragment } from 'react';
import { useQuery } from 'react-query';
import { apiUrl } from '../settings';
import { useStore } from '../store';

const StatisticsAbout = () => {
  const language = useStore((state) => state.language);
  const { isLoading, error, data } = useQuery('statistics', () =>
    fetch(`${apiUrl}/statistics`).then((res) => res.json()),
  );

  if (isLoading) return 'Loading...';

  if (error) return 'An error has occurred: ' + error.message;

  return (
    <div className="statistics d-flex flex-wrap">
      {data.map((d, i) => {
        if (d.statistic) {
          return (
            <Fragment key={i}>
              {d.statistic.map((s, j) => {
                let cl = 'statistic l d-inline-flex mb-0';
                return (
                  // { i == 3 &&
                  //   <br />
                  // }
                  <div className={cl} key={j}>
                    <div>
                      <p className="value">{s.value}</p>
                      <p className="name">{labelWrapper[s.name][language]}</p>
                    </div>
                  </div>
                );
              })}
            </Fragment>
          );
        }
      })}
    </div>
  );
};

function About() {
  const language = useStore((state) => state.language);

  return (
    <Layout>
      <div className="about pad pad-b">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-space">
                {language === 'nl'
                  ? 'De Eco Food Map'
                  : 'About the Eco Food Map'}
              </h2>
            </div>
          </div>
        </div>
        <section className="intro-image"></section>

        <div className="container-fluid pad pad-b">
          <div className="row">
            <div className="col-lg-6">
              {language === 'nl' ? (
                <h2 className="pr-lg-5">
                  Steden zijn belangrijke actoren voor&nbsp;de&nbsp;opbouw
                  van&nbsp;een&nbsp;meer duurzame samenleving
                </h2>
              ) : (
                <h2 className="pr-lg-5">
                  Cities are key&nbsp;actors in&nbsp;building a&nbsp;more
                  sustainable society
                </h2>
              )}
            </div>
            <div className="col-lg-6">
              {language === 'nl' ? (
                <p className="font-serif-m">{`Hoewel ze wereldwijd slechts 2,2% van het landoppervlak beslaan,
                    verbruiken ze tot wel 60% van de waterreserves voor
                    woondoeleinden, produceren ze meer dan 50% van de mondiale
                    afvalberg en zijn ze verantwoordelijk voor 60 tot 80% van de
                    wereldwijde uitstoot van broeikasgassen. Maar ze genereren ook
                    meer dan 80% van het mondiale bruto binnenlands product (BBP).
                    Steden hebben dus het potentieel om positieve veranderingen te
                    bewerkstelligen die wereldwijde gevolgen kunnen hebben.`}</p>
              ) : (
                <p className="font-serif-m">{`Although they occupy only 2.2% of terrestrial area, they use up to 60% of water resources for residential purposes, generate over 50% of waste, and are responsible for 60-80% of the world’s greenhouse gas emissions. But they also generate more than 80% of the global gross domestic product (GDP). As such, cities have the potential to foster positive change with worldwide impacts.`}</p>
              )}
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-space">Leuven</h2>
            </div>
          </div>
        </div>
        <section className="geographic-context">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-xl-4 text-center">
                <img className="img-fluid about-map" src={map} />
              </div>
              <div className="col-lg-6 col-xl-8">
                <StatisticsAbout />
              </div>
            </div>
          </div>
        </section>

        <div className="container-fluid pad-small">
          <div className="row">
            <div className="col-lg-6 mt-5">
              {language === 'nl' ? (
                <p className="font-serif-m mb-3 pr-lg-5 pr-xl-0">
                  Steden staan voor meerdere uitdagingen op vlak van voeding.
                  Een geïntegreerd en coherent voedselbeleid uitwerken is echter
                  niet eenvoudig. Het vergt samenwerking over de grenzen van
                  beleidsdomeinen heen. Toch maken steeds meer steden werk van
                  een voedselstrategie. Daarbij luidt steeds vaker de vraag naar
                  de impact van beleidskeuzes, zowel op korte en middellange
                  termijn als op lange termijn.
                </p>
              ) : (
                <p className="font-serif-m mb-3 pr-lg-5 pr-xl-0">
                  Cities face multiple challenges in terms of food. However,
                  developing an integrated and coherent food policy is not easy.
                  It requires cooperation across the boundaries of policy
                  domains. Nevertheless, more and more cities are working on a
                  food strategy.
                </p>
              )}

              {language === 'nl' ? (
                <p className="font-serif-m mb-3 pr-lg-5 pr-xl-0">
                  Het consortium (Leuven 2030, shiftN, KU Leuven en Rikolto)
                  ontwikkelde een gebruiksvriendelijke online tool die het
                  voedselsysteem in en rond Leuven in kaart brengt en zo een
                  inzicht biedt in de complexiteit ervan. De tool toont welke
                  indicatoren kunnen gebruikt worden voor de evaluatie, en biedt
                  tevens een monitor voor de Stad Leuven. Daarnaast is er ook
                  ruimte voor alle actoren, initiatieven en experten die
                  betrokken zijn bij de Leuvense voedselstrategie. De tool wil
                  de dialoog over de Leuvense voedselstrategie faciliteren, en
                  ondersteunt het beleid bij het maken van gerichte keuzes. De
                  tool is eveneens een belangrijk instrument voor het evalueren
                  van de bereikte resultaten in de context van de Roadmap
                  2030-2050.
                </p>
              ) : (
                <p className="font-serif-m mb-3 pr-lg-5 pr-xl-0">
                  {
                    'The consortium (Leuven 2030, shiftN, KU Leuven and Rikolto) developed a user-friendly food system mapping digital tool that allows a thorough understanding of the complexity of the food system in and around Leuven. The tool shows which indicators can be used for evaluation, and also provides a monitor for the City of Leuven. In addition, it includes all actors, initiatives and experts involved in the Leuven food strategy. The tool aims to facilitate the dialogue about the Leuven food strategy, and supports the policy makers in making targeted choices. The tool is also an important instrument for evaluating the results achieved in the context of the Roadmap 2030-2050.'
                  }
                </p>
              )}
            </div>
            <div className="col-lg-6 col-xl-4 offset-xl-2 mt-5">
              <div className="row">
                <div className="col-sm-6 col-lg-12 mb-space">
                  <img className="img-fluid" src={img1} />
                </div>
                <div className="col-sm-6 col-lg-12">
                  <img className="img-fluid" src={img2} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default About;
