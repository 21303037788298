import React, { useState, useEffect } from 'react';
import { LinePath, AreaClosed } from '@visx/shape';
import { flatten } from 'lodash';
import { extent } from 'd3-array';
import { Bar } from '@visx/shape';
import { scaleTime, scaleLinear } from '@visx/scale';
import { AxisLeft, AxisBottom } from '@visx/axis';

const RelatedViz = ({ data, w = 200, id, hover, setHover }) => {
  const svgWidth = 750;
  const svgHeight = 30;
  const margin = 5;
  const height = svgHeight - margin * 2;
  const width = svgWidth / 2 - margin * 2;
  const yScale = scaleTime({
    range: [0, height],
  });
  const xScale = scaleLinear({
    range: [0, width],
  });
  const yMax = width;
  const flattenDataset = flatten(data.map((d) => d.dataset));

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: '0 5px',
      }}
    >
      <div style={{ fontSize: '11px', opacity: 0.5 }}>Comparisons</div>
      {data.map((g, j) => {
        //const dates = extent(flattenDataset, (d) => d.year);
        const bounds = extent(flattenDataset, (d) => d.data);
        xScale.domain([bounds[0] / 2, bounds[1]]);
        // yScale.domain([
        //   new Date(`01-01-$
        // {dates[0]}`),
        //   new Date(`01-01-${dates[1]}`),
        // ]);
        return (
          <svg height={svgHeight} width={svgWidth} key={`svg-${j}`}>
            <g transform={`translate(${0},${2})`}>
              {g.dataset.map((d, i) => {
                const barWidth = xScale(Number(d.data)) ?? 0;
                const barHeight = 10;
                const barX = 0;
                const barY = 10;
                const isHovered = d === hover;
                d.indicator = g;
                return (
                  <g key={`bar-${i}`}>
                    <Bar
                      x={barX}
                      y={barY}
                      width={barWidth}
                      height={barHeight}
                      fill={
                        id === g.id
                          ? isHovered
                            ? 'black'
                            : '#5100ff'
                          : isHovered
                          ? 'black'
                          : '#E9ECEF'
                      }
                      onMouseEnter={() => setHover(d)}
                      onMouseLeave={() => setHover(null)}
                      style={{ cursor: 'pointer' }}
                    />
                    <text
                      dx={svgWidth / 2 + 10}
                      dy={15}
                      fontSize="12"
                      fill="rgba(0,0,0,0.5)"
                      fontFamily="Sohne, sans-serif"
                    >
                      {g.indicator_nl}
                    </text>
                  </g>
                );
              })}
            </g>
            {/* <g transform={`translate(${0}, ${0})`}>
              <line
                x1={margin * 2}
                x2={svgWidth}
                y1={svgHeight - margin * 2}
                y2={svgHeight - margin * 2}
                stroke={'rgba(0,0,0,.20)'}
                strokeDasharray={'4 2'}
              ></line>
              <line
                x1={margin * 2}
                x2={svgWidth}
                y1={yScale(bounds[1])}
                y2={yScale(bounds[1])}
                stroke={'rgba(0,0,0,.20)'}
                strokeDasharray={'4 2'}
              ></line>
              <text
                dx={margin * 2 - 10}
                dy={svgHeight - margin * 2}
                fontSize="10"
                fill={'rgba(0,0,0,.5)'}
                textAnchor="end"
              >
                0
              </text>
              <text
                dx={margin * 2 - 10}
                dy={yScale(bounds[1]) + 5}
                fontSize="10"
                fill={'rgba(0,0,0,.5)'}
                textAnchor="end"
              >
                {bounds[1]}
              </text>
            </g> */}
          </svg>
        );
      })}
    </div>
  );
};

export default RelatedViz;
