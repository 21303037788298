import React from 'react';
import { Link } from '@reach/router';
import { closeMenuMobile, labelWrapper } from '../utils';
import LinkScrollTop from '../components/LinkScrollTop';
import { useStore } from '../store';
import x from '../assets/images/menu-x.svg';

function MenuMobile() {
  const language = useStore((state) => state.language);
  const setLanguage = (language) => {
    useStore.setState({ language: language });
  };
  return (
    <nav id="menu-mobile">
      
      <div className="top">

        <LinkScrollTop
          className="font-strong-m color-white hover-white-50"
          to={'/'}
        >Eco Food Map <span className="font-serif-m header-serif">Leuven</span></LinkScrollTop>

        <a onClick={() => { closeMenuMobile(); }}
           className="color-white-50"
        ><img src={x} /></a>
      </div>

      <div className="middle">
        <LinkScrollTop
          className="item-large"
          to={'/'}
        >Home</LinkScrollTop>
        <LinkScrollTop
          className="item-large"
          to={'/indicators'}
        >{labelWrapper['indicators'][language]}</LinkScrollTop>
        <LinkScrollTop
          className="item-large"
          to={'/experts'}
        >{labelWrapper['experts'][language]}</LinkScrollTop>
        <LinkScrollTop
          className="item-large"
          to={'/initiatives'}
        >{labelWrapper['initiatives'][language]}</LinkScrollTop>
        <LinkScrollTop
          className="item-large"
          to={'/actors'}
        >{labelWrapper['actors'][language]}</LinkScrollTop>
        <LinkScrollTop
          className="item-large"
          to={'/about'}
        >{labelWrapper['about'][language]}</LinkScrollTop>
      </div>

      <div className="bottom">
        <span>
          <a
            className={`xs-language-el ${language === 'en' ? 'active' : ''}`}
            onClick={() => setLanguage('en')}
          >ENGLISH</a>
          <span className="color-white-50">{" · "}</span>
          <a
            className={`xs-language-el ${language === 'nl' ? 'active' : ''}`}
            onClick={() => setLanguage('nl')}
          >NEDERLANDS</a>
        </span>
      </div>

    </nav>
  );
}

export default MenuMobile;
