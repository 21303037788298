import React, { useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../settings';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { labelWrapper, deSluggify } from '../utils';

import Switch from '@material-ui/core/Switch';

const FormExpert = ({ language }) => {
  const { register, errors, handleSubmit } = useForm();
  const [formCompiled, setFormCompiled] = useState(false);
  const [interestError, setInterestError] = useState(false);
  const [state, setState] = useState({
    faird_trade: false,
    social_inclusion: false,
    organic: false,
    sustainable_technologies: false,
    community_shared_agriculture: false,
    legislation_policy: false,
    animal_based: false,
    biodiversity: false,
    short_supply: false,
    food_safety: false,
    logistics: false,
    waste_disposal: false,
    health: false,
    plant_based: false,
    cultivation_technique: false,
  });

  const onSubmit = (data) => {
    console.log(data);
    data.status = 'draft';
    data.interests = state;
    const hasInterestTrue = Object.keys(state).some((k) => state[k]);
    if (hasInterestTrue) {
      setInterestError(false);
    } else {
      setInterestError(true);
      return;
    }

    data.contacts = [
      {
        contact_email: data.contact_email,
        contact_name: data.contact_name,
        contact_tel: data.contact_tel,
      },
    ];
    data.location = {
      address: data.address,
      latitude: data.latitude || null,
      longitude: data.longitude || null,
    };
    data.published_at = null;

    axios
      .post(`${apiUrl}/experts`, data)
      .then((res) => {
        console.log(res.data);
        setFormCompiled(true);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  console.log('errors', errors);

  return (
    <div>
      <section className="bg-purple pb-4 mb-space-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h2
                className="color-white text-center font-serif-l"
                style={{ maxWidth: '600px', margin: '0 auto' }}
              >
                {labelWrapper['application_expert'][language]}
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section id="form-wrapper">
        <div className="container-fluid narrow">
          <div className="row">
            {!formCompiled ? (
              <div className="col-12">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <TextField
                    id="outlined-basic"
                    label={labelWrapper['name'][language]}
                    variant="outlined"
                    className="formInputText"
                    name="name"
                    inputProps={{
                      name: 'name',
                      ref: register({ required: true }),
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    label={labelWrapper['description'][language]}
                    variant="outlined"
                    className="formInputText"
                    inputProps={{
                      name: 'description',
                      ref: register({ required: true }),
                    }}
                  />

                  <TextField
                    id="outlined-basic"
                    label={labelWrapper['website'][language]}
                    variant="outlined"
                    inputProps={{
                      name: 'website',
                      ref: register(),
                    }}
                  />

                  <div className="row">
                    <div className="col-sm-6">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['location_address'][language]}
                        variant="outlined"
                        inputProps={{
                          name: 'address',
                          ref: register(),
                        }}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="col-sm-6 col-sm-3">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['latitude'][language]}
                        variant="outlined"
                        inputProps={{
                          name: 'latitude',
                          ref: register(),
                        }}
                      />
                    </div>
                    <div className="col-sm-6 col-sm-3">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['longitude'][language]}
                        variant="outlined"
                        inputProps={{
                          name: 'longitude',
                          ref: register(),
                        }}
                      />
                    </div>
                  </div>

                  <FormGroup>
                    <TextField
                      id="outlined-basic"
                      label={labelWrapper['email_contact'][language]}
                      variant="outlined"
                      inputProps={{
                        name: 'contact_email',
                        ref: register({ required: true }),
                      }}
                    />
                    <TextField
                      id="outlined-basic"
                      label={labelWrapper['name_contact'][language]}
                      variant="outlined"
                      inputProps={{
                        name: 'contact_name',
                        ref: register(),
                      }}
                    />
                    <TextField
                      id="outlined-basic"
                      label={labelWrapper['telephone_contact'][language]}
                      variant="outlined"
                      inputProps={{
                        name: 'contact_tel',
                        ref: register(),
                      }}
                    />
                  </FormGroup>

                  <div className="row mt-4">
                    <div className="col-sm-6">
                      <FormLabel component="legend">
                        {labelWrapper['interests'][language]}
                      </FormLabel>
                      <FormGroup>
                        {Object.keys(state)
                          .slice(0, 8)
                          .map((interest, key) => {
                            console.log(interest);
                            return (
                              <FormControlLabel
                                key={interest}
                                control={
                                  <Switch
                                    checked={state[interest]}
                                    onChange={handleChange}
                                    name={interest}
                                  />
                                }
                                label={
                                  labelWrapper['interestslist'][interest][
                                    language
                                  ]
                                }
                              />
                            );
                          })}
                      </FormGroup>
                    </div>
                    <div className="col-sm-6">
                      <FormGroup>
                        {Object.keys(state)
                          .slice(8, 16)
                          .map((interest, key) => {
                            console.log(interest);
                            return (
                              <FormControlLabel
                                key={interest}
                                control={
                                  <Switch
                                    checked={state[interest]}
                                    onChange={handleChange}
                                    name={interest}
                                  />
                                }
                                label={
                                  labelWrapper['interestslist'][interest][
                                    language
                                  ]
                                }
                              />
                            );
                          })}
                      </FormGroup>
                    </div>
                  </div>

                  {errors.name && (
                    <div style={{ color: 'red' }}>Mame is required</div>
                  )}
                  {errors.description && (
                    <div style={{ color: 'red' }}>Description is required</div>
                  )}
                  {errors.email_contact && (
                    <div style={{ color: 'red' }}>Email is required</div>
                  )}
                  {interestError && (
                    <div style={{ color: 'red' }}>
                      At least one interest is required
                    </div>
                  )}

                  <input
                    type="submit"
                    value={labelWrapper['submit'][language]}
                    className="submitButton"
                  />
                </form>
              </div>
            ) : (
              <div className="col-12">
                <h2>
                  Request sent! our team will process your request as soon as
                  possible.
                </h2>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormExpert;
