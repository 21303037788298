import React, { useEffect } from 'react';
import { flatten } from 'lodash';
import { useStore } from '../store';
import ExpertCard from './ExpertCard';
import InitiativeCard from './InitiativeCard';
import ActorCard from './ActorCard';
import { navigateScrollTop, labelWrapper } from '../utils';

const Cards = ({ selected }) => {
  const language = useStore((state) => state.language);
  const initiatives = useStore((state) => state.initiatives);
  const experts = useStore((state) => state.experts);
  const actors = useStore((state) => state.actors);

  const selCards =
    selected === 'initiatives'
      ? initiatives
      : selected === 'experts'
      ? experts
      : actors;

  return (
    <div className="container-fluid">
      <div className="row stakeholders-cards-container">
        {selCards.length &&
          flatten(selCards)
            .slice(0, 6)
            .map((d, i) => {
              if (selected === 'initiatives') {
                return <InitiativeCard d={d} key={i} />;
              }
              if (selected === 'actors') {
                return <ActorCard d={d} key={i} />;
              }
              if (selected === 'experts') {
                return <ExpertCard d={d} key={i} />;
              }
            })}
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <a
            className="button l my-space-3"
            onClick={() => {
              navigateScrollTop(`/${selected}`);
            }}
          >
            {labelWrapper['see_all'][language] +
              ' ' +
              labelWrapper[selected][language]}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Cards;
