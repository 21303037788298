import React from 'react';
import { groupBy } from 'lodash';
import Layout from './Layout';
import { useStore, getFilteredIndicators } from '../store';
import IndicatorCard from '../components/IndicatorCard';
import { labelWrapper } from '../utils';

function IndicatorsSubset({ subsetKey, subsetValue }) {
  const language = useStore((state) => state.language);
  const indicators = useStore((state) => state.indicators);
  const indicatorsSubset = getFilteredIndicators(subsetKey, subsetValue);

  console.log(subsetKey, subsetValue);

  return (
    <Layout>
      <div className="Home">
        <section className="cards-wrapper pad pad-b">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h2 className="">
                  {labelWrapper['indicatorCategories'][subsetValue][language] ||
                    subsetValue}
                </h2>
              </div>
            </div>
          </div>

          <div className="container-fluid mt6">
            <div className="row">
              {indicatorsSubset.map((d, j) => {
                return <IndicatorCard d={d} key={j} />;
              })}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default IndicatorsSubset;
