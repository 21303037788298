import { Suspense, useEffect } from 'react';
import { useQuery, QueryCache, ReactQueryCacheProvider } from 'react-query';
import { useStore } from '../store';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Loading from '../components/Loading';
import MenuCover from '../components/MenuCover';
import MenuMobile from '../components/MenuMobile';
const queryCache = new QueryCache();

function Layout({ children }) {
  const loading = useStore((state) => state.loading);
  const initiatives = useStore((state) => state.initiatives);
  const experts = useStore((state) => state.experts);
  const actors = useStore((state) => state.actors);
  const indicators = useStore((state) => state.indicators);
  const getInitiatives = useStore((state) => state.fetchInitiatives);
  const getExperts = useStore((state) => state.fetchExperts);
  const getActors = useStore((state) => state.fetchActors);
  const getIndicators = useStore((state) => state.fetchIndicators);
  useEffect(() => {
    if (initiatives.length === 0) {
      getInitiatives();
    }
    if (experts.length === 0) {
      getExperts();
    }
    if (actors.length === 0) {
      getActors();
    }
    if (indicators.length === 0) {
      getIndicators();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      useStore.setState({ loading: false });
    }, 1000);
  }, [indicators]);

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      {loading && <Loading />}
      <div className="Home">
        <Header />
        <Suspense fallback={<Loading />}>
          <main>{children}</main>
        </Suspense>
        <Footer />
        <MenuMobile />
        <MenuCover />
      </div>
    </ReactQueryCacheProvider>
  );
}

export default Layout;
