import { navigate } from '@reach/router';
import { isMobile, isTablet } from 'react-device-detect';

export const getFormattedTruthyMultipleSelect = (d, selectKey) => {
  if (d && d[selectKey]) {
    const values = Object.values(d[selectKey]);
    const keys = Object.keys(d[selectKey]);
    return keys.filter((k, i) => k !== 'id' && values[i] === true);
  } else {
    return [];
  }
};

// export const randomIntroSentence = () => {
//   // --- Uses Markdown
//   const sentences = [
//     {
//       en:
//         'Leuven counts _7 100% vegetarian restaurants_ and _6 100% plant-based_ restaurants',
//       nl:
//         'Leuven telt _7 vegetarische restaurants_ en _6 restaurants_ met een _100% plantaardig_ aanbod',
//     },
//     {
//       en:
//         'Leuven counts _3 packaging-free shops_ and _20 community vegetable gardens_',
//       nl: 'Leuven telt _3 verpakkingsvrije winkels_ en _20 buurtmoestuinen_',
//     },
//     {
//       en:
//         'Leuven counts _3 organic farms_ and _7 farms that sell directly to customers_ through Community-Supported Agriculture',
//       nl:
//         'Leuven telt _3 biologische landbouwbedrijven_ _7 boerderijen die rechtstreeks aan de consument verkopen_ via gemeenschapslandbouw',
//     },
//     {
//       en:
//         'Leuven counts _13 short food supply chain_ initiatives and _6 weekly markets_',
//       nl: 'Leuven telt _13 korte keten_ initiatieven _6 wekelijkse markten_',
//     },
//   ];
//   const index = Math.floor(Math.random() * sentences.length);
//   return sentences[index];
// };

export const getPhotoIfPresent = (d) => {
  if (
    d.photo &&
    d.photo.formats &&
    d.photo.formats.thumbnail &&
    d.photo.formats.thumbnail.url
  ) {
    return d.photo.formats.thumbnail.url;
  } else {
    return false;
  }
};

export const getLogoIfPresent = (d) => {
  if (
    d.logo &&
    d.logo.formats &&
    d.logo.formats.thumbnail &&
    d.logo.formats.thumbnail.url
  ) {
    return d.logo.formats.thumbnail.url;
  } else {
    return false;
  }
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

export const labelWrapper = {
  interestslist: {
    logistics: {
      en: 'Logistics',
      nl: 'Logistiek',
    },
    organic: {
      en: 'Organic',
      nl: 'Biologische voeding',
    },
    biodiversity: {
      en: 'Biodiversity',
      nl: 'Biodiversiteit',
    },
    community_shared_agriculture: {
      en: 'Community Supported Agriculture',
      nl: 'Gemeenschapslandbouw (CSA)',
    },
    sustainable_technologies: {
      en: 'Sustainable Technologies',
      nl: 'Duurzame technologieën',
    },
    legislation_policy: {
      en: 'Legislation and Policy',
      nl: 'Wetgeving en beleid',
    },
    animal_based: {
      en: 'Animal Based',
      nl: 'Dierlijke voeding',
    },
    food_safety: {
      en: 'Food Safety',
      nl: 'Voedselveiligheid',
    },
    plant_based: {
      en: 'Plant Based',
      nl: 'Plantaardige voeding',
    },
    cultivation_technique: {
      en: 'Cultivation Techniques',
      nl: 'Teelttechniek en -tips',
    },
    short_supply: {
      en: 'Short Supply',
      nl: 'Korte keten',
    },
    health: {
      en: 'Health',
      nl: 'Gezonde voeding',
    },
    faird_trade: {
      en: 'Fair trade',
      nl: 'Eerlijke handel',
    },
    social_inclusion: {
      en: 'Social Inclusion',
      nl: 'Sociale inclusie',
    },
    waste_disposal: {
      en: 'Waste Disposal',
      nl: 'Afvalverwerking',
    },
    prevention_food_waste: {
      en: 'Prevention of food waste',
      nl: 'Preventie van voedselverspilling',
    },
  },
  indicatorCategories: {
    social_sustainability: {
      en: 'Social Sustainability',
      nl: 'Sociaal',
    },
    economic_sustainability: {
      en: 'Economic',
      nl: 'Economisch',
    },
    education_awareness: {
      en: 'Education Awareness',
      nl: 'Educatie',
    },
    accessibility: {
      en: 'Accessibility',
      nl: 'Toegankelijkheid',
    },
    agricultural_sector: {
      en: 'Agricultural Sector',
      nl: 'Landbouwsector in beeld',
    },
    health_welbeing_nutrition_utilisation: {
      en: 'Health Welbeing Nutrition Utilisation',
      nl: 'Gezondheid en welzijn',
    },
    workforce_conditions: {
      en: 'Workforce Conditions',
      nl: 'Werkomstandigheden',
    },
    short_local_supply_chain: {
      en: 'Short Local Supply Chain',
      nl: 'Korte keten',
    },
    employment_opportunities: {
      en: 'Employment Opportunities',
      nl: 'Werkgelegenheid',
    },
    food_safety: {
      en: 'Food Safety',
      nl: 'Voedselveiligheid',
    },
    sustainable_food_consumption: {
      en: 'Sustainable Food Consumption',
      nl: 'Duurzame voedselconsumptie',
    },
    mutual_benefits: {
      en: 'Mutual Benefits',
      nl: 'Wederzijdse voordelen',
    },
    ghg_emissions: {
      en: 'GHG Emissions',
      nl: 'GHG emissies',
    },
    ecosystem_management: {
      en: 'Ecosystem Management',
      nl: 'Ecosysteembeheer',
    },
    affordability: {
      en: 'Affordability',
      nl: 'Betaalbaarheid',
    },
    accessibility_affordability: {
      en: 'Accessibility and Affordability',
      nl: 'Toegankelijkheid en betaalbaarheid',
    },
    social_inclusion: {
      en: 'Social Inclusion',
      nl: 'Sociale inclusie',
    },
    short_food_supply_chain: {
      en: 'Short Food Supply Chain',
      nl: 'Korte keten',
    },
    local_economy: {
      en: 'Local Economy',
      nl: 'Lokale economie',
    },
    fair_trade: {
      en: 'Fair Trade',
      nl: 'Eerlijke handel',
    },
    environmental_sustainability: {
      en: 'Environmental Sustainability',
      nl: 'Milieu',
    },
    production: {
      en: 'Production',
      nl: 'Productie',
    },
    impact: {
      en: 'Impact',
      nl: 'Impact',
    },
    waste_disposal: {
      en: 'Waste Disposal',
      nl: 'Afvalverwerking',
    },
    urban_rural_integration: {
      en: 'Urban Rural Integration',
      nl: 'Relatie stad-platteland',
    },
    food_governance: {
      en: 'Policy',
      nl: 'Beleid',
    },
    governance: {
      en: 'Governance',
      nl: 'Beleid',
    },
    participation: {
      en: 'Participation',
      nl: 'Participatie',
    },
    food_loss_waste: {
      en: 'Food loss and waste',
      nl: 'Voedselverlies en -verspilling',
    },
  },
  data: {
    en: 'Data',
    nl: 'Data',
  },
  indicators: {
    en: 'Indicators',
    nl: 'Indicatoren',
  },
  experts: {
    en: 'Experts',
    nl: 'Experten',
  },
  expert: {
    en: 'Expert',
    nl: 'Experten',
  },
  actors: {
    en: 'Actors',
    nl: 'Actoren',
  },
  actor: {
    en: 'Actor',
    nl: 'Acteur',
  },
  initiatives: {
    en: 'Initiatives',
    nl: 'Initiatieven',
  },
  initiative: {
    en: 'Initiative',
    nl: 'Initiatief',
  },
  all_experts: {
    en: 'All experts',
    nl: 'Alles experten',
  },
  all_initiatives: {
    en: 'All initiatives',
    nl: 'Alles initiatieven',
  },
  all_actors: {
    en: 'All actors',
    nl: 'Alles acteurs',
  },
  // About: 'De Eco Food Map',
  about: {
    en: 'About',
    nl: 'Informatie',
  },
  more: {
    en: 'more',
    nl: 'meer',
  },
  learn_more: {
    en: 'Learn more',
    nl: 'Ontdek meer',
  },
  indicators_dashboard: {
    en: 'Indicators',
    nl: 'Indicatoren',
  },
  see_all: {
    en: 'See all',
    nl: 'Toon alle',
  },
  geo_map: {
    en: 'Geographic map',
    nl: 'Geografische kaart',
  },
  sys_map: {
    en: 'System map',
    nl: 'Systemische kaart',
  },
  mapping_food_system: {
    en: 'Mapping the food system',
    nl: 'Voedselsysteem in beeld',
  },
  food_ecosystem: {
    en: 'Food ecosystem',
    nl: 'Voedselnetwerk',
  },
  categories: {
    en: 'Categories',
    nl: 'Categorieën',
  },
  search_actors: {
    en: 'Search Actors',
    nl: 'Zoek Actoren',
  },
  all_actors: {
    en: 'All Actors',
    nl: 'Alle Actoren',
  },
  all_initiatives: {
    en: 'All Initiatives',
    nl: 'Alle Initiatieven',
  },
  search_initiatives: {
    en: 'Search Initiatives',
    nl: 'Zoek Initiatieven',
  },
  all_experts: {
    en: 'All Experts',
    nl: 'Alle Experten',
  },
  search_experts: {
    en: 'Search Experts',
    nl: 'Zoek Experten',
  },
  'Sub-Categories': 'Subcategorieën',
  source: {
    en: 'Source',
    nl: 'Bron',
  },
  impact_areas: {
    en: 'Impact Areas',
    nl: 'Subcategorie',
  },
  responsible: {
    en: 'Responsible',
    nl: 'Verantwoordelijke data-verzameling',
  },
  related_indicators: {
    en: 'Related indicators',
    nl: 'Gerelateerde indicatoren',
  },
  related_stakeholders: {
    en: 'Related Stakeholders',
    nl: 'Gerelateerde Stakeholders',
  },
  related: {
    en: 'Related',
    nl: 'Gerelateerd',
  },
  description: {
    en: 'Description',
    nl: 'Beschrijving',
  },
  geographic_area: {
    en: 'Geographic Area',
    nl: 'Geografisch schaalniveau',
  },
  'Initiatives ecosystem': 'Initiatieven',
  'Experts ecosystem': 'Experten',
  'Actors ecosystem': 'Acteurs',
  stakeholder_ecosystem: {
    en: 'Stakeholder ecosystem',
    nl: 'Stakeholder ecosystem',
  },
  category: {
    en: 'Category',
    nl: 'Categorie',
  },
  event: {
    en: 'Event',
    nl: 'Evenement',
  },
  awareness_campaign: {
    en: 'Awareness campaign',
    nl: 'Sensibiliseringscampagne',
  },
  pilot_study_project: {
    en: 'Pilot study project',
    nl: 'Proefproject',
  },
  education_training: {
    en: 'Education training',
    nl: 'Opleiding',
  },
  network_platform: {
    en: 'Network platform',
    nl: 'Netwerk',
  },
  citizens_initiative: {
    en: 'Citizens initiative',
    nl: 'Burgerinitiatief',
  },
  interests: {
    en: 'Interests',
    nl: 'Interessedomein',
  },
  fairtrade: {
    en: 'Fairtrade',
    nl: 'Eerlijke handel',
  },
  fair_trade: { en: 'Faird trade', nl: 'Eerlijke handel' },
  social_inclusion: {
    en: 'Social inclusion',
    nl: 'Sociale inclusie',
  },
  organic: {
    en: 'Organic',
    nl: 'Biologische voeding',
  },
  sustainable_technologies: {
    en: 'Sustainable technologies',
    nl: 'Duurzame technologieën',
  },
  community_shared_agriculture: {
    en: 'Community supported agriculture',
    nl: 'Gemeenschapslandbouw (CSA)',
  },
  legislation_policy: {
    en: 'Legislation and policy',
    nl: 'Wetgeving en beleid',
  },
  animal_based: {
    en: 'Animal based',
    nl: 'Dierlijke voeding',
  },
  Biodiversity: 'Biodiversiteit',
  short_supplu: {
    en: 'Short supply',
    nl: 'Korte keten',
  },
  food_safety: {
    en: 'Food safety',
    nl: 'Voedselveiligheid',
  },
  Logistics: 'Logistiek',
  Logistisc: 'Logistiek',
  waste_disposal: {
    en: 'Waste disposal',
    nl: 'Afvalverwerking',
  },
  health: {
    en: 'Health',
    nl: 'Gezonde voeding',
  },
  plant_based: {
    en: 'Plant based',
    nl: 'Plantaardige voeding',
  },
  cultivation_technique: {
    en: 'Cultivation techniques',
    nl: 'Teelttechniek en tips',
  },
  target: {
    en: 'Target',
    nl: 'Doelgroep',
  },
  B2B: 'Business-to-Business',
  B2C: 'Business-to-Consumers',
  farms_food_production: {
    en: 'Farms food production',
    nl: 'Landbouwbedrijven en voedingsproducenten',
  },
  retailers: {
    en: 'Retailers',
    nl: 'Retailers',
  },
  horeca: {
    en: 'Horeca',
    nl: 'Horeca',
  },
  distribution: {
    en: 'Distribution',
    nl: 'Distributie',
  },
  schools: {
    en: 'Schools',
    nl: 'Scholen',
  },
  babies: {
    en: 'Babies',
    nl: "Baby's en peuters (0-4 jaar)",
  },
  children: { en: 'Children', nl: 'Kinderen (4-12 jaar)' },
  youth: { en: 'Youth', nl: 'Jongeren (12-18 jaar)' },
  students: { en: 'Students', nl: 'Studenten (18-25 jaar)' },
  elder: { en: 'Elder', nl: 'Ouderen (65+)' },
  low_income_households: {
    en: 'Low income households',
    nl: 'Mensen in kansarmoede',
  },
  disabled_people: { en: 'Disabled people', nl: 'Mensen met een beperking' },
  start_date: {
    en: 'Start date',
    nl: 'Startdatum',
  },
  end_date: { en: 'End date', nl: 'Einddatum' },
  start_date_hour: { en: 'Start hour', nl: 'Startuur' },
  end_date_hour: { en: 'End hour', nl: 'Einduur' },
  event_date: { en: 'Event date', nl: 'Event date' },
  finished: {
    en: 'Finished',
    nl: 'Afgelopen',
  },
  location: { en: 'Location', nl: 'Locatie' },
  address: {
    en: 'Address',
    nl: 'Adres',
  },
  capacity: {
    en: 'Capacity',
    nl: 'Capaciteit',
  },
  price: {
    en: 'Price',
    nl: 'Prijs',
  },
  registration: {
    en: 'Registration',
    nl: 'Registratie',
  },
  funding: {
    en: 'Funding',
    nl: 'Sponsoring',
  },
  contacts: {
    en: 'Contacts',
    nl: 'Contact',
  },
  email: {
    en: 'Email',
    nl: 'Email',
  },
  telephone: {
    en: 'Telephone',
    nl: 'Telefoon',
  },
  name: {
    en: 'Name',
    nl: 'Naam',
  },
  infos: {
    en: 'Website',
    nl: 'Website',
  },
  activities: {
    en: 'Activities',
    nl: 'Activiteiten',
  },
  farm_food_production: {
    en: 'Farm food production',
    nl: 'Landbouw en voedingsproductie',
  },
  food_processing: {
    en: 'Food processing',
    nl: 'Voedselverwerking',
  },
  retail: {
    en: 'Retail',
    nl: 'Retail',
  },
  catering: {
    en: 'Catering and restaurants',
    nl: 'catering en restaurants',
  },
  education: {
    en: 'Education',
    nl: 'Onderwijs',
  },
  government_agency: {
    en: 'Government agency',
    nl: 'Overheidsinstantie',
  },
  distribution: {
    en: 'Distribution',
    nl: 'Distributie',
  },
  volunteer_organisation: {
    en: 'Volunteer organisation',
    nl: 'Vrijwilligersvereniging',
  },
  advisory_board: {
    en: 'Advisory board',
    nl: 'Adviesorgaan',
  },
  research_innovation: {
    en: 'Research innovation',
    nl: 'Onderzoek en innovatie',
  },
  interest_group: {
    en: 'Interest group',
    nl: 'Belangenvereniging',
  },
  knowledge_centre: {
    en: 'Knowledge centre',
    nl: 'Kennis- en expertisecentrum',
  },
  support_centre: {
    en: 'Support centre',
    nl: 'Steunpunt',
  },
  youth_comminty_work: {
    en: 'Youth comminty work',
    nl: 'Jeugd- en buurtwerking',
  },
  employment_agency: {
    en: 'Employment agency',
    nl: 'Uitzendbureau',
  },
  network: {
    en: 'Network',
    nl: 'Netwerkorganisatie',
  },
  website: {
    en: 'Website',
    nl: 'Website',
  },
  services: {
    en: 'Services',
    nl: 'Diensten',
  },
  products: {
    en: 'Products',
    nl: 'Producten',
  },
  vegetables: {
    en: 'Vegetables',
    nl: 'Groenten',
  },
  fruit: { en: 'Fruit', nl: 'Fruit' },
  meat: {
    en: 'Meat',
    nl: 'Vleesproducten',
  },
  staplings: {
    en: 'staplings',
    nl: 'staplings',
  },
  diary_products: { en: 'Diary products', nl: 'Zuivel' },
  dairy_products: { en: 'Diary products', nl: 'Zuivel' },
  drinks: { en: 'Drinks', nl: 'Dranken' },
  seeds_nuts: { en: 'Seeds nuts', nl: 'Zaden en noten' },
  other_regional: {
    en: 'Other regional products',
    nl: 'Andere verwerkte streekproducten',
  },
  prepared_meals: { en: 'Prepared meals', nl: 'Bereide maaltijden' },
  labelled_products: {
    en: 'Labelled products',
    nl: 'Producten met een label',
  },
  regional_product_label: {
    en: 'Regional product label',
    nl: 'Streekproductenlabel',
  },
  fairtrade_label: { en: 'Fairtrade label', nl: 'FairTrade label' },
  organic_label: { en: 'Organic label', nl: 'Biologisch label' },
  vegeterian_label: { en: 'Vegetarian label', nl: 'Vegetarisch label' },
  food_waste: { en: 'Food waste', nl: 'Voedselverspilling' },
  surplus: { en: 'Surplus', nl: 'Overschotten' },
  action_surplus: { en: 'Action surplus', nl: 'Voedseloverschotten' },
  donating: { en: 'Donating', nl: 'Schenken' },
  selling_reduced: {
    en: 'Selling at reduced price',
    nl: 'Aan verminderde prijs verkopen',
  },
  animal_feed: { en: 'Animal feed', nl: 'Gebruiken als dierenvoeding' },
  composting: {
    en: 'Composting',
    nl: 'Composteren (of sorteren ans GFT-afval)',
  },
  burn_dump: { en: 'Burn or dump', nl: 'Als afval verbranden of storten' },
  food_surplus: { en: 'Food surplus', nl: 'Voedseloverschotten' },
  opening_hours: {
    en: 'Opening hours',
    nl: 'Openingsuren',
  },
  monday: { en: 'Monday', nl: 'Maandag' },
  tuesday: { en: 'Tuesday', nl: 'Dinsdag' },
  wednesday: { en: 'Wednesday', nl: 'Woensdag' },
  thursday: { en: 'Thursday', nl: 'Donderdag' },
  friday: { en: 'Friday', nl: 'Vrijdag' },
  saturday: { en: 'Saturday', nl: 'Zaterdag' },
  sunday: { en: 'Sunday', nl: 'Zondag' },
  enterpresize_size: {
    en: 'Enterprise size',
    nl: 'Grootte van de onderneming',
  },
  nacecode: {
    en: 'Nacecode',
    nl: 'NACE-code',
  },
  municipality_leuven: {
    en: 'Municipality of Leuven',
    nl: 'Gemeente Leuven',
  },
  flanders: {
    en: 'Flanders',
    nl: 'Flanders',
  },
  national: {
    en: 'National',
    nl: 'National',
  },
  arrondissement_leuven: {
    en: 'Arrondissement of Leuven',
    nl: 'Arrondissement of Leuven',
  },
  submunicipality_leuven: {
    en: 'Submunicipality of Leuven',
    nl: 'Submunicipality of Leuven',
  },
  population: {
    en: 'population',
    nl: 'bevolking',
  },
  population_growth: {
    en: 'population growth',
    nl: 'bevolkingsgroei',
  },
  surface: {
    en: 'surface',
    nl: 'oppervlakte',
  },
  child_poverty: {
    en: 'child poverty',
    nl: 'kinderarmoede',
  },
  unemployment: {
    en: 'unemployment',
    nl: 'werkloosheid',
  },
  municipality_leuven: {
    en: 'Municipality of Leuven',
    nl: 'Groot Leuven',
  },
  national: {
    en: 'National level',
    nl: 'Nationaal niveau',
  },
  flanders: {
    en: 'Flanders',
    nl: 'Vlaanderen',
  },
  arrondissement_leuven: {
    en: 'Arrondissement of Leuven',
    nl: 'Arrondissement Leuven',
  },
  flemish_brabant: {
    en: 'Flemish Brabant',
    nl: 'Vlaams-Brabant',
  },
  submunicipality_leuven: {
    en: 'Submunicipality Leuven',
    nl: 'Deelgemeente Leuven',
  },
  number: {
    en: 'Amount',
    nl: 'Aantal',
  },
  euro_month: {
    en: 'euro/month',
    nl: 'euro/maand',
  },
  euro_person_year: {
    en: 'euro/person/year',
    nl: 'euro/persoon/jaar',
  },
  '%': {
    en: '%',
    nl: '%',
  },
  hectare: {
    en: 'Hectare',
    nl: 'Hectare',
  },
  perc_total_area: {
    en: '% of total area',
    nl: '% van totale oppervlakte',
  },
  population: {
    en: 'population',
    nl: 'bevolking',
  },
  population_growth: {
    en: 'population growth',
    nl: 'bevolkingsgroei',
  },
  surface: {
    en: 'area',
    nl: 'oppervlakte',
  },
  child_poverty: {
    en: 'child poverty',
    nl: 'kinderarmoede',
  },
  unemployment: {
    en: 'unemployment',
    nl: 'werkloosheid',
  },
  ton_co2: {
    en: 'ton co2',
    nl: 'ton co2',
  },
  application_actor: {
    en: 'Send us your application as actor',
    nl:
      'Is jouw bedrijf of organisatie een acteur in het Leuvense voedselsysteem? Voeg dan hieronder je informatie toe',
  },
  application_expert: {
    en: 'Send us your application as expert of the Leuven food system',
    nl:
      'Ben je een expert in het Leuvense voedselsysteem? Voeg dan hieronder je informatie toe',
  },
  application_initiative: {
    en: 'Send us your initiative',
    nl: 'Voeg hieronder je initiatief toe',
  },
  name: {
    en: 'Name',
    nl: 'Naam',
  },
  description: {
    en: 'Description',
    nl: 'Beschrijving',
  },
  node_linked: {
    en: 'This node is linked to:',
    nl: 'Dit knooppunt is gerelateerd aan:',
  },
  website: { en: 'Website', nl: 'Website' },
  location_address: { en: 'Address', nl: 'Adres' },
  latitude: { en: 'Latitude', nl: 'Breedtegraad' },
  longitude: { en: 'Longitude', nl: 'Lengtegraad' },
  email_contact: { en: 'Email', nl: 'E-mail' },
  name_contact: { en: 'Contact name', nl: 'Naam contact' },
  telephone_contact: { en: 'Telephone', nl: 'Telefoonnummer' },
  submit: { en: 'Submit your data', nl: 'Dien je gegevens in' },
  include: {
    en: 'include',
    nl: 'bevat',
  },
  explanation: {
    en: 'Click the categories to filter results',
    nl: 'Klik op de bovenstaande categorieën om je zoekresultaten te verfijnen',
  },
};

export const getTranslation = ({ key, categoryKey, language }) => {
  if (categoryKey) {
    return labelWrapper[categoryKey][key]
      ? labelWrapper[categoryKey][key][language]
      : categoryKey;
  }
  return labelWrapper[key] ? labelWrapper[key][language] : key;
};

export const navigateScrollTop = (destination) => {
  navigate(destination);
  window.scrollTo(0, 0);
};

export const openMenuMobile = () => {
  const b = document.body;
  if (!b.classList.contains('menu-open')) {
    b.classList.add('menu-open');
  }
};

export const closeMenuMobile = () => {
  const b = document.body;
  if (b.classList.contains('menu-open')) {
    b.classList.remove('menu-open');
  }
};

export const deSluggify = (str) => {
  return str.replaceAll(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
};

export const addhttp = (url) => {
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = 'http://' + url;
  }
  return url;
};

export const cleanUrlText = (url) => {
  const urlParts = url
    .replace('http://', '')
    .replace('https://', '')
    .split(/[/?#]/);
  return urlParts[0];
};

export const formatNumberWithCommas = (x) => {
  //console.log('---> ' + x);
  // if (["number", "string"].includes(typeof x)) {
  //   console.log("if passed")
  //   return "+"+ x.toString().replace(/B(?=(d{3})+(?!d))/g, ",");
  // }
  return x;
};

export const isMobileWithTablet = isMobile
  ? isTablet
    ? window.innerWidth < window.innerHeight
      ? true
      : false
    : true
  : false;

export const validURL = (str) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

/** AP Web Utility
 *  Query current breakpoint in bootstrap style.
 *  Examples:
 *
 *  console.log(breakpointIs("xs", "only"));
 *  console.log(breakpointIs("md", "down"));
 *  console.log(breakpointIs("lg", "up"));
 */
export const breakpointIs = (breakpointName, compare) => {
  var breakpoints = [
    { name: 'xs', index: 1, minWidth: 0 },
    { name: 'sm', index: 2, minWidth: 576 },
    { name: 'md', index: 3, minWidth: 768 },
    { name: 'lg', index: 4, minWidth: 992 },
    { name: 'xl', index: 5, minWidth: 1200 },
  ];
  var w = window.innerWidth;
  var current = breakpoints[0];
  breakpoints.forEach(function (b, i) {
    if (w > b.minWidth) {
      current = b;
    }
  });
  var breakpoint = breakpoints.find(function (e) {
    return e.name === breakpointName;
  });
  if (!breakpoint) {
    throw '(30298140) Unknown breakpointName ' + breakpointName;
  }
  var breakpointIndex = breakpoint.index;
  if (compare === 'only') {
    return current.index === breakpointIndex;
  } else if (compare === 'down') {
    return current.index <= breakpointIndex;
  } else if (compare === 'up') {
    return current.index >= breakpointIndex;
  }
};
