import React from 'react';
import { closeMenuMobile } from '../utils';
import { useStore } from '../store';

function MenuCover() {
  const searchOpen = useStore((state) => state.searchOpen);
  const setSearchOpen = (searchOpen) => {
    useStore.setState({ searchOpen: searchOpen });
  };

  return (
    <div
      id="menu-cover"
      onClick={() => {
        closeMenuMobile();
        setSearchOpen(false);
      }}
    ></div>
  );
}

export default MenuCover;
