import React, { useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../settings';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { labelWrapper, deSluggify } from '../utils';

import Switch from '@material-ui/core/Switch';

const FormActor = ({ language }) => {
  const { register, errors, handleSubmit } = useForm();
  const [formCompiled, setFormCompiled] = useState(false);
  const [interestError, setInterestError] = useState(false);
  const [activityError, setActivityError] = useState(false);
  const [state, setState] = useState({
    faird_trade: false,
    social_inclusion: false,
    organic: false,
    sustainable_technologies: false,
    community_shared_agriculture: false,
    legislation_policy: false,
    animal_based: false,
    biodiversity: false,
    short_supply: false,
    food_safety: false,
    logistics: false,
    waste_disposal: false,
    health: false,
    plant_based: false,
    cultivation_technique: false,
  });

  const [activityState, setActivityState] = useState({
    advisory_board: false,
    catering: false,
    distribution: false,
    education: false,
    employment_agency: false,
    farm_food_production: false,
    food_processing: false,
    government_agency: false,
    interest_group: false,
    knowledge_centre: false,
    network: false,
    research_innovation: false,
    retail: false,
    support_centre: false,
    volunteer_organisation: false,
    youth_comminty_work: false,
  });

  const [productState, setProductState] = useState({
    diary_products: false,
    drinks: false,
    fruit: false,
    meat: false,
    other_regional: false,
    prepared_meals: false,
    seeds_nuts: false,
    staplings: false,
    vegetables: false,
  });

  const [labelledproductState, setLabelledProductState] = useState({
    regional_product_label: false,
    fairtrade_label: false,
    organic_label: false,
    vegeterian_label: false,
  });

  const [foodWasteState, setFoodWasteState] = useState({
    surplus: false,
  });

  const [actionSurplusState, setActionSurplusState] = useState({
    animal_feed: false,
    burn_dump: false,
    composting: false,
    donating: false,
    selling_reduced: false,
  });

  const [foodSurplusState, setFoodSurplusState] = useState({
    dairy_products: false,
    drinks: false,
    fruit: false,
    meat: false,
    other_regional: false,
    prepared_meals: false,
    seeds_nuts: false,
    staplings: false,
    vegetables: false,
  });

  const [targetState, setTargetState] = useState({
    b2c: false,
    b2b: false,
  });

  const [targetb2cState, setTargetb2cState] = useState({
    babies: false,
    children: false,
    youth: false,
    students: false,
    elder: false,
    low_income_households: false,
    disabled_people: false,
  });

  const [targetb2bState, setTargetb2bState] = useState({
    farms_food_production: false,
    retailers: false,
    horeca: false,
    distribution: false,
    schools: false,
  });

  const onSubmit = (data) => {
    data.status = 'draft';

    data.interests = state;
    const hasInterestTrue = Object.keys(state).some((k) => state[k]);
    if (hasInterestTrue) {
      setInterestError(false);
    } else {
      setInterestError(true);
      return;
    }

    data.activities = activityState;
    const hasActivityTrue = Object.keys(activityState).some(
      (k) => activityState[k],
    );
    if (hasActivityTrue) {
      setActivityError(false);
    } else {
      setActivityError(true);
      return;
    }

    data.products = productState;
    data.products.labelled_products = labelledproductState;
    data.food_waste = foodWasteState;
    data.target = {
      ...targetState,
      target_b2b: { ...targetb2bState },
      target_b2c: { ...targetb2cState },
    };
    data.contacts = [
      {
        contact_email: data.contact_email,
        contact_name: data.contact_name,
        contact_tel: data.contact_tel,
      },
    ];
    data.location = {
      address: data.address,
      latitude: data.latitude || null,
      longitude: data.longitude || null,
    };
    data.published_at = null;

    console.log(data);

    axios
      .post(`${apiUrl}/stakeholders`, data)
      .then((res) => {
        console.log(res.data);
        setFormCompiled(true);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleActivityChange = (event) => {
    setActivityState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleProductsChange = (event) => {
    setProductState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleLabelledProductsChange = (event) => {
    setLabelledProductState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleFoodWasteChange = (event) => {
    setFoodWasteState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleFoodSurplusChange = (event) => {
    setFoodWasteState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleActionSurplusChange = (event) => {
    setActionSurplusState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleTargetChange = (event) => {
    setTargetState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleTargetb2bChange = (event) => {
    setTargetb2bState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleTargetb2cChange = (event) => {
    setTargetb2cState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <div>
      <section className="bg-purple pb-4 mb-space-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h2
                className="color-white text-center font-serif-l"
                style={{ maxWidth: '600px', margin: '0 auto' }}
              >
                {labelWrapper['application_actor'][language]}
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section id="form-wrapper">
        <div className="container-fluid narrow">
          <div className="row">
            {!formCompiled ? (
              <div className="col-12">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <TextField
                    id="outlined-basic"
                    label={labelWrapper['name'][language]}
                    variant="outlined"
                    className="formInputText"
                    name="name"
                    inputProps={{
                      name: 'name',
                      ref: register({ required: true }),
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    label={labelWrapper['description'][language]}
                    variant="outlined"
                    className="formInputText"
                    inputProps={{
                      name: 'description',
                      ref: register({ required: true }),
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    label={labelWrapper['website'][language]}
                    variant="outlined"
                    inputProps={{
                      name: 'website',
                      ref: register({ required: true }),
                    }}
                  />

                  {/* <div className="row">
                    <div className="col-sm-6">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['enterprise_size'][language]}
                        variant="outlined"
                        inputProps={{
                          name: 'enterprise_size',
                          ref: register(),
                        }}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['nace_code'][language]}
                        variant="outlined"
                        inputProps={{
                          name: 'nacecode',
                          ref: register(),
                        }}
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div> */}

                  <div className="row">
                    <div className="col-sm-6">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['location_address'][language]}
                        variant="outlined"
                        inputProps={{
                          name: 'address',
                          ref: register(),
                        }}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="col-6 col-sm-3">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['latitude'][language]}
                        variant="outlined"
                        inputProps={{
                          name: 'latitude',
                          ref: register(),
                        }}
                      />
                    </div>
                    <div className="col-6 col-sm-3">
                      <TextField
                        id="outlined-basic"
                        label={labelWrapper['longitude'][language]}
                        variant="outlined"
                        inputProps={{
                          name: 'longitude',
                          ref: register(),
                        }}
                      />
                    </div>
                  </div>

                  <FormGroup>
                    <TextField
                      id="outlined-basic"
                      label={labelWrapper['email_contact'][language]}
                      variant="outlined"
                      inputProps={{
                        name: 'contact_email',
                        ref: register({ required: true }),
                      }}
                    />
                    <TextField
                      id="outlined-basic"
                      label={labelWrapper['name_contact'][language]}
                      variant="outlined"
                      inputProps={{
                        name: 'contact_name',
                        ref: register(),
                      }}
                    />
                    <TextField
                      id="outlined-basic"
                      label={labelWrapper['telephone_contact'][language]}
                      variant="outlined"
                      inputProps={{
                        name: 'contact_tel',
                        ref: register(),
                      }}
                    />
                  </FormGroup>

                  <div className="row mt-4">
                    <div className="col-sm-6">
                      <FormLabel component="legend">
                        {labelWrapper['interests'][language]}
                      </FormLabel>
                      <FormGroup>
                        {Object.keys(state)
                          .slice(0, 8)
                          .map((interest, key) => {
                            return (
                              <FormControlLabel
                                key={interest}
                                control={
                                  <Switch
                                    checked={state[interest]}
                                    onChange={handleChange}
                                    name={interest}
                                  />
                                }
                                label={
                                  labelWrapper['interestslist'][interest][
                                    language
                                  ]
                                }
                              />
                            );
                          })}
                      </FormGroup>
                    </div>
                    <div className="col-sm-6">
                      <FormGroup>
                        {Object.keys(state)
                          .slice(8, 16)
                          .map((interest, key) => {
                            return (
                              <FormControlLabel
                                key={interest}
                                control={
                                  <Switch
                                    checked={state[interest]}
                                    onChange={handleChange}
                                    name={interest}
                                  />
                                }
                                label={
                                  labelWrapper['interestslist'][interest][
                                    language
                                  ]
                                }
                              />
                            );
                          })}
                      </FormGroup>
                    </div>
                  </div>

                  {/* ACTIVITIES */}
                  <div className="row mt-4">
                    <div className="col-sm-6">
                      <FormLabel component="legend">
                        {labelWrapper['activities'][language]}
                      </FormLabel>
                      <FormGroup>
                        {Object.keys(activityState)
                          .slice(0, 8)
                          .map((activity, key) => {
                            return (
                              <FormControlLabel
                                key={activity}
                                control={
                                  <Switch
                                    checked={activityState[activity]}
                                    onChange={handleActivityChange}
                                    name={activity}
                                  />
                                }
                                label={
                                  labelWrapper[activity][language] || activity
                                }
                              />
                            );
                          })}
                      </FormGroup>
                    </div>
                    <div className="col-sm-6">
                      <FormGroup>
                        {Object.keys(activityState)
                          .slice(8, 16)
                          .map((activity, key) => {
                            return (
                              <FormControlLabel
                                key={activity}
                                control={
                                  <Switch
                                    checked={activityState[activity]}
                                    onChange={handleActivityChange}
                                    name={activity}
                                  />
                                }
                                label={
                                  labelWrapper[activity][language] || activity
                                }
                              />
                            );
                          })}
                      </FormGroup>
                    </div>
                  </div>

                  {/* PRODUCTS */}
                  <div className="row mt-4">
                    <div className="col-sm-6">
                      <FormLabel component="legend">
                        {labelWrapper['products'][language]}
                      </FormLabel>
                      <FormGroup>
                        {Object.keys(productState)
                          .slice(0, 5)
                          .map((product, key) => {
                            return (
                              <FormControlLabel
                                key={product}
                                control={
                                  <Switch
                                    checked={productState[product]}
                                    onChange={handleProductsChange}
                                    name={product}
                                  />
                                }
                                label={
                                  labelWrapper[product][language] || product
                                }
                              />
                            );
                          })}
                      </FormGroup>
                    </div>
                    <div className="col-sm-6">
                      <FormGroup>
                        {Object.keys(productState)
                          .slice(5, 10)
                          .map((product, key) => {
                            return (
                              <FormControlLabel
                                key={product}
                                control={
                                  <Switch
                                    checked={productState[product]}
                                    onChange={handleProductsChange}
                                    name={product}
                                  />
                                }
                                label={
                                  labelWrapper[product][language] || product
                                }
                              />
                            );
                          })}
                      </FormGroup>
                    </div>
                  </div>

                  {/* LABELLED PRODUCTS */}
                  <div className="row mt-4">
                    <div className="col-sm-6">
                      <FormLabel component="legend">
                        {labelWrapper['labelled_products'][language]}
                      </FormLabel>
                      <FormGroup>
                        {Object.keys(labelledproductState).map(
                          (product, key) => {
                            return (
                              <FormControlLabel
                                key={product}
                                control={
                                  <Switch
                                    checked={labelledproductState[product]}
                                    onChange={handleLabelledProductsChange}
                                    name={product}
                                  />
                                }
                                label={
                                  labelWrapper[product][language] || product
                                }
                              />
                            );
                          },
                        )}
                      </FormGroup>
                    </div>
                    <div className="col-sm-6"></div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-sm-6">
                      <FormLabel component="legend">
                        {labelWrapper['food_waste'][language]}
                      </FormLabel>
                      <FormGroup>
                        {Object.keys(foodWasteState).map((waste, key) => {
                          return (
                            <FormControlLabel
                              key={waste}
                              control={
                                <Switch
                                  checked={foodWasteState[waste]}
                                  onChange={handleFoodWasteChange}
                                  name={waste}
                                />
                              }
                              label={labelWrapper[waste][language] || waste}
                            />
                          );
                        })}
                      </FormGroup>
                    </div>
                    <div className="col-sm-6"></div>
                  </div>

                  {/* FOOD SURPLUS */}

                  <div className="row mt-4">
                    {foodWasteState.surplus && (
                      <div className="col-sm-6">
                        <FormLabel component="legend">
                          {labelWrapper['food_surplus'][language]}
                        </FormLabel>
                        <FormGroup>
                          {Object.keys(foodSurplusState).map((surplus, key) => {
                            return (
                              <FormControlLabel
                                key={surplus}
                                control={
                                  <Switch
                                    checked={foodSurplusState[surplus]}
                                    onChange={handleFoodSurplusChange}
                                    name={surplus}
                                  />
                                }
                                label={
                                  labelWrapper[surplus][language] || surplus
                                }
                              />
                            );
                          })}
                        </FormGroup>
                      </div>
                    )}
                    {foodWasteState.surplus && (
                      <div className="col-sm-6">
                        <FormLabel component="legend">
                          {labelWrapper['action_surplus'][language]}
                        </FormLabel>
                        <FormGroup>
                          {Object.keys(actionSurplusState).map(
                            (surplus, key) => {
                              return (
                                <FormControlLabel
                                  key={surplus}
                                  control={
                                    <Switch
                                      checked={actionSurplusState[surplus]}
                                      onChange={handleActionSurplusChange}
                                      name={surplus}
                                    />
                                  }
                                  label={
                                    labelWrapper[surplus][language] || surplus
                                  }
                                />
                              );
                            },
                          )}
                        </FormGroup>
                      </div>
                    )}
                  </div>

                  {/* TARGET */}
                  <div className="row mt-4">
                    <div className="col-sm-6">
                      <FormLabel component="legend">
                        {labelWrapper['target'][language]}
                      </FormLabel>
                      <FormGroup>
                        {Object.keys(targetState).map((target, key) => {
                          return (
                            <FormControlLabel
                              key={target}
                              control={
                                <Switch
                                  checked={targetState[target]}
                                  onChange={handleTargetChange}
                                  name={target}
                                />
                              }
                              label={target}
                            />
                          );
                        })}
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row mt-4">
                    {targetState.b2c && (
                      <div className="col-sm-6">
                        <FormGroup>
                          <FormLabel component="legend">Target b2c</FormLabel>
                          {Object.keys(targetb2cState).map((target, key) => {
                            return (
                              <FormControlLabel
                                key={target}
                                control={
                                  <Switch
                                    checked={targetb2cState[target]}
                                    onChange={handleTargetb2cChange}
                                    name={target}
                                  />
                                }
                                label={labelWrapper[target][language]}
                              />
                            );
                          })}
                        </FormGroup>
                      </div>
                    )}
                    {targetState.b2b && (
                      <div className="col-sm-6">
                        <FormGroup>
                          <FormLabel component="legend">Target b2b</FormLabel>

                          {Object.keys(targetb2bState).map((target, key) => {
                            return (
                              <FormControlLabel
                                key={target}
                                control={
                                  <Switch
                                    checked={targetb2bState[target]}
                                    onChange={handleTargetb2bChange}
                                    name={target}
                                  />
                                }
                                label={labelWrapper[target][language]}
                              />
                            );
                          })}
                        </FormGroup>
                      </div>
                    )}
                  </div>

                  {errors.name && (
                    <div style={{ color: 'red' }}>Mame is required</div>
                  )}
                  {errors.description && (
                    <div style={{ color: 'red' }}>Description is required</div>
                  )}
                  {errors.website && (
                    <div style={{ color: 'red' }}>website is required</div>
                  )}
                  {errors.email_contact && (
                    <div style={{ color: 'red' }}>Email is required</div>
                  )}
                  {interestError && (
                    <div style={{ color: 'red' }}>
                      At least one interest is required
                    </div>
                  )}
                  {activityError && (
                    <div style={{ color: 'red' }}>
                      At least one activity is required
                    </div>
                  )}

                  <input
                    type="submit"
                    value={labelWrapper['submit'][language]}
                    className="submitButton"
                  />
                </form>
              </div>
            ) : (
              <div className="col-12">
                <h2>
                  Request sent! our team will process your request as soon as
                  possible.
                </h2>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormActor;
