/*

Domaide per paul

- dizionari per interests, indicator 

*/

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Redirect, Router } from '@reach/router';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import Home from './pages/Home';
import Initiatives from './pages/Initiatives';
import Initiative from './pages/Initiative';
import Experts from './pages/Experts';
import Expert from './pages/Expert';
import reportWebVitals from './reportWebVitals';
import Actors from './pages/Actors';
import Actor from './pages/Actor';
import Indicators from './pages/Indicators';
import IndicatorsSubset from './pages/IndicatorsSubset';
import IndicatorPage from './pages/IndicatorPage';
import About from './pages/About';
import Form from './pages/Form';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={''}>
      <Router>
        <Indicators exact path="/indicators" />
        <IndicatorsSubset exact path="/indicators/:subsetKey/:subsetValue" />
        <IndicatorPage exact path="/indicators/:id" />
        <Actors exact path="/actors" />
        <Actor exact path="/actors/:id" />
        <Experts exact path="/experts" />
        <Expert exact path="/experts/:id" />
        <Initiatives exact path="/initiatives" />
        <Initiative exact path="/initiatives/:id" />
        <About exact path="/about" />
        <Form exact path="/form" />
        <Home path="/" />
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
