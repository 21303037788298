import React, { Fragment } from 'react';
import { useStore, getGroupedIndicators } from '../store';
// import { navigate } from '@reach/router';
import {
  navigateScrollTop,
  formatNumberWithCommas,
  labelWrapper,
  getTranslation,
} from '../utils';
import Trend from './Trend';
import RelatedViz from './RelatedViz';

const IndicatorCard = ({ d }) => {
  const language = useStore((state) => state.language);
  const isLinked = d.linkeddataset ? true : false;
  const group = d.group_id ? getGroupedIndicators(d.id, d.group_id) : null;
  const geo = d.geographic_area ? labelWrapper[d.geographic_area][language] : null;

  return (
    <div className="col-md-6 col-xl-4 card-stakeholder-col">
      <div
        className="card-indicator"
        onClick={(e) => {
          navigateScrollTop(`/indicators/${d.id}`);
        }}
      >
        <header></header>
        <div className="content">
          <div className="top">
            <div className="left">
              {isLinked ? (
                <Fragment>
                  <p className="stat-text">
                    {formatNumberWithCommas(d.linkeddataset.count)}
                  </p>
                  <p className="stat-unit">{d.source} </p>
                  <p className="label color-purple">{geo}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <p className="stat-text">
                    {d.dataset[d.dataset.length - 1]
                      ? d.dataset[d.dataset.length - 1].data
                      : 'n/a'}
                    {d.dataset.length &&
                    d.dataset[d.dataset.length - 1] &&
                    d.dataset[d.dataset.length - 1].unit.length == 1
                      ? d.dataset[d.dataset.length - 1].unit
                      : null}
                  </p>
                  {
                    <p className="stat-unit">
                      {d.dataset.length > 0 &&
                      d.dataset[d.dataset.length - 1] &&
                      d.dataset[d.dataset.length - 1].unit.length > 1
                        ? getTranslation({
                            key: d.dataset[d.dataset.length - 1].unit,
                            language: language,
                          })
                        : null}
                    </p>
                  }
                  <p className="label color-purple">{geo}</p>
                </Fragment>
              )}
            </div>
            {group ? (
              <div className="right related">
                <RelatedViz data={group} id={d.id} w={150} />
              </div>
            ) : (
              <div className="right trend">
                {d.visualisation_function === 'timeline' && (
                  <Trend data={d.dataset} w={85} />
                )}
              </div>
            )}
          </div>

          <div className="bottom">
            <div className="font-serif-m font-400 mb-2">
              {language === 'nl' ? d.indicator_nl : d.indicator_en}
            </div>
            <div className="label">
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  navigateScrollTop(
                    `/indicators/indicator_category/${d.indicator_category}`,
                  );
                }}
              >{`${
                language === 'nl'
                  ? d.indicator_category_nl
                  : d.indicator_category_en
              }`}</a>
              <span className="mx-1">›</span>
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  navigateScrollTop(
                    `/indicators/impact_areas_id/${d.impact_areas_id}`,
                  );
                }}
              >{`${
                language === 'nl' ? d.impact_areas_nl : d.impact_areas_en
              }`}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndicatorCard;
