import React, { useState } from 'react';
import LayoutPage from './LayoutPage';
import {
  getPhotoIfPresent,
  getFormattedTruthyMultipleSelect,
  labelWrapper,
  addhttp,
  cleanUrlText,
} from '../utils';
import noImg from '../assets/images/no-img.png';
import { apiUrl } from '../settings';
import { useQuery } from 'react-query';
import Loading from '../components/Loading';
import InitiativeCard from '../components/InitiativeCard';
import ActorCard from '../components/ActorCard';
import { useStore } from '../store';

function Expert({ id }) {
  const language = useStore((state) => state.language);
  const { isLoading, error, data } = useQuery(`expert${id}`, () =>
    fetch(`${apiUrl}/experts/${id}`).then((res) => res.json()),
  );

  if (isLoading) return <Loading />;

  if (error) return 'An error has occurred: ' + error.message;

  const expert = data;
  const p = getPhotoIfPresent(expert);
  const imageUrl = p ? apiUrl + p : noImg;
  const imgStyle = { backgroundImage: 'url(' + imageUrl + ')' };
  console.log('expert', expert);
  expert.actors = expert.stakeholders;

  const interests = getFormattedTruthyMultipleSelect(expert, 'interests');

  return (
    <LayoutPage>
      <div className="stakeholder-page expert">
        {/*
        { expert && (
          <section className="py-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h2 className="mb-1 mt-space">
                    <a className="" onClick={() => { navigateScrollTop(`/experts`); }}>{labelWrapper['Experts']}</a>
                    {"  ›  "}
                    {expert.name}
                  </h2>
                </div>
              </div>
            </div>
          </section>
        )}
        */}

        {expert && (
          <section className="py-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h2 className="mb-1 mt-space">{expert.name}</h2>
                  <h5 className="color-c1 font-strong-m mt-2 pb-2">
                    {labelWrapper['expert'][language] + ' — '}
                    {expert.website && (
                      <a
                        href={addhttp(expert.website)}
                        target="_blank"
                        className="external color-c1"
                      >
                        {cleanUrlText(expert.website)}
                      </a>
                    )}
                  </h5>
                </div>
              </div>
            </div>
          </section>
        )}

        <section className="bg-white py-4 pb-5">
          {expert && (
            <div className="container-fluid">
              <div className="row content">
                <div className="col-12 my-4 d-xl-none">
                  <div className="round-img" style={imgStyle}></div>
                </div>

                <div className="col-md-8 col-lg-12 col-xl-10 two-cols-lg">
                  <div className="break-inside-avoid">
                    <div className="font-sans-s color-black-50 mt-4">
                      {labelWrapper['description'][language]}
                    </div>
                    <div className="font-serif-m mt-1 mr-sm-5">
                      {language === 'en'
                        ? expert.description_en
                        : expert.description}
                    </div>
                  </div>

                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {labelWrapper['interests'][language]}
                    </div>
                    <div className="font-serif-m mt-1">
                      {interests.map((interest, j) => {
                        const interestName =
                          labelWrapper['interestslist'][interest][language];
                        return (
                          <span key={j}>
                            {j > 0 ? ', ' : ''}
                            {interestName}
                          </span>
                        );
                      })}
                    </div>
                  </div>

                  {expert.contacts.length > 0 &&
                    (expert.contacts[0].contact_email ||
                      expert.contacts[0].contact_email ||
                      expert.contacts[0].contact_email) && (
                      <div className="break-inside-avoid mr-xl-5 pt-1">
                        <div className="font-sans-s color-black-50 mt-4">
                          {labelWrapper['contacts'][language]}
                        </div>
                        <div className="font-serif-m mt-1">
                          {expert.contacts.map((contact, o) => {
                            return (
                              <div key={o}>
                                {contact.contact_email && (
                                  <div>{contact.contact_email}</div>
                                )}
                                {contact.contact_name && (
                                  <div>{contact.contact_name}</div>
                                )}
                                {contact.contact_tel && (
                                  <div>{contact.contact_tel}</div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                  {expert.location && expert.location.address && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['location'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        <div>{expert.location.address}</div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-md-2 my-4 d-none d-xl-flex justify-content-end">
                  <div className="round-img" style={imgStyle}></div>
                </div>
              </div>
            </div>
          )}
        </section>

        {(expert.initiatives.length > 0 || expert.actors.length > 0) && (
          <section className="stakeholders-cards py-4 pb-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h2 className="mt-space-2 mb-space">
                    {labelWrapper['related'][language]}
                  </h2>
                </div>
              </div>
              <div className="row">
                {expert.initiatives.map((d, i) => {
                  if (d) {
                    return <InitiativeCard d={d} key={i} />;
                  }
                })}
                {expert.actors.map((d, i) => {
                  if (d) {
                    return <ActorCard d={d} key={i} />;
                  }
                })}
              </div>
            </div>
          </section>
        )}
      </div>
    </LayoutPage>
  );
}

export default Expert;
