import { Suspense, useEffect } from 'react';
import { useQuery, QueryCache, ReactQueryCacheProvider } from 'react-query';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Loading from '../components/Loading';
import MenuCover from '../components/MenuCover';
import MenuMobile from '../components/MenuMobile';
const queryCache = new QueryCache();

function LayoutPage({ children }) {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <div className="Home">
        <Header />
        <Suspense fallback={<Loading />}>
          <main>{children}</main>
        </Suspense>
        <Footer />
        <MenuMobile />
        <MenuCover />
      </div>
    </ReactQueryCacheProvider>
  );
}

export default LayoutPage;
