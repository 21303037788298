import React, { useState } from 'react';
import Layout from './Layout';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import FormInitiative from '../components/FormInitiative';
import { labelWrapper } from '../utils';
import FormExpert from '../components/FormExpert';
import FormActor from '../components/FormActor';
import { useStore } from '../store';

const Form = ({}) => {
  const location = useLocation();
  const language = useStore((state) => state.language);
  const searchParams = parse(location.search);
  console.log(searchParams);
  const [categorySelected, setCategorySelected] = useState(
    searchParams.type || 'actors',
  );

  return (
    <Layout>
      <div className="Home">
        <section className="bg-purple py-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-2"></div>
              <div
                className="col-8"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <a
                  role="button"
                  className={`button selector white white-active ${
                    categorySelected === 'experts' ? 'active' : ''
                  }`}
                  onClick={() => setCategorySelected('experts')}
                >
                  {labelWrapper['experts'][language]}
                </a>
                <a
                  role="button"
                  className={`button selector white white-active ${
                    categorySelected === 'actors' ? 'active' : ''
                  }`}
                  onClick={() => setCategorySelected('actors')}
                >
                  {labelWrapper['actors'][language]}
                </a>
                <a
                  role="button"
                  className={`button selector white white-active ${
                    categorySelected === 'initiatives' ? 'active' : ''
                  }`}
                  onClick={() => setCategorySelected('initiatives')}
                >
                  {labelWrapper['initiatives'][language]}
                </a>
              </div>
              <div className="col-2"></div>
            </div>
          </div>
        </section>

        <div
          className="col-12"
          style={{
            display: categorySelected === 'initiatives' ? 'block' : 'none',
          }}
        >
          <FormInitiative language={language} />
        </div>
        <div
          className="col-12"
          style={{
            display: categorySelected === 'experts' ? 'block' : 'none',
          }}
        >
          <FormExpert language={language} />
        </div>
        <div
          className="col-12"
          style={{
            display: categorySelected === 'actors' ? 'block' : 'none',
          }}
        >
          <FormActor language={language} />
        </div>
      </div>
    </Layout>
  );
};

export default Form;
