import React from 'react';
import { apiUrl } from '../settings';
import {
  getFormattedTruthyMultipleSelect,
  getPhotoIfPresent,
  labelWrapper,
} from '../utils';
import style from '../styles/Card.module.css';
import noImg from '../assets/images/no-img.png';
// import { navigate } from '@reach/router';
import { navigateScrollTop } from '../utils';
import { useStore } from '../store';

const ExpertCard = ({ d }) => {
  const language = useStore((state) => state.language);
  const interests = getFormattedTruthyMultipleSelect(d, 'interests');
  const maxWords = 22;
  const translated = language === 'en' ? d.description_en : d.description;
  const words = translated ? translated.split(' ') : [];
  let description;
  if (words.length == 0) {
    description = 'No description.';
  } else if (words.length <= maxWords) {
    description = translated;
  } else {
    description = words.splice(0, maxWords).join(' ') + '…';
  }
  const p = getPhotoIfPresent(d);
  const imageUrl = p ? apiUrl + p : noImg;
  const imgStyle = { backgroundImage: 'url(' + imageUrl + ')' };

  return (
    <div className="col-md-6 col-xl-4 card-stakeholder-col">
      <div
        className="card-stakeholder"
        onClick={() => navigateScrollTop(`/experts/${d.id}`)}
      >
        <header>
          <div className="left">
            <div className="font-sans-s font-700 color-c1">
              {labelWrapper['expert'][language].toUpperCase()}
            </div>
            <div className="card-title">{d.name}</div>
            <div className="font-sans-s color-blackish-80">
              {interests.map((interest, j) => {
                const interestName =
                  labelWrapper['interestslist'][interest][language];
                return (
                  <span key={j}>
                    {j > 0 ? ', ' : ''}
                    {interestName}
                  </span>
                );
              })}
            </div>
          </div>
          <div className="right">
            <div className="round-img thumb" style={imgStyle}></div>
          </div>
        </header>

        <div className="card-body">
          <div className="font-serif-m font-400">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default ExpertCard;
