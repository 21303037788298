import React, { useRef, useState } from 'react';
import LayoutPage from './LayoutPage';
import { apiUrl } from '../settings';
import { flatten } from 'lodash';
import {
  getLogoIfPresent,
  getFormattedTruthyMultipleSelect,
  labelWrapper,
  addhttp,
  cleanUrlText,
} from '../utils';
import { useStore } from '../store';
import noImg from '../assets/images/no-img.png';
import { useQuery } from 'react-query';
import Loading from '../components/Loading';
import ExpertCard from '../components/ExpertCard';
import InitiativeCard from '../components/InitiativeCard';

function Actor({ id }) {
  const language = useStore((state) => state.language);
  const { isLoading, error, data } = useQuery(`stakeholder${id}`, () =>
    fetch(`${apiUrl}/stakeholders/${id}`).then((res) => res.json()),
  );

  if (isLoading) return <Loading />;

  if (error) return 'An error has occurred: ' + error.message;

  const actor = data;
  console.log('actor', actor);
  const p = getLogoIfPresent(actor);
  const imageUrl = p ? apiUrl + p : noImg;
  const imgStyle = { backgroundImage: 'url(' + imageUrl + ')' };
  const interests = getFormattedTruthyMultipleSelect(actor, 'interests');
  const products = getFormattedTruthyMultipleSelect(actor, 'products');
  const labelledProducts = getFormattedTruthyMultipleSelect(
    actor.products,
    'labelled_products',
  );
  const activities = getFormattedTruthyMultipleSelect(actor, 'activities');
  const actionsurplus = getFormattedTruthyMultipleSelect(
    actor.food_waste,
    'action_surplus',
  );
  const foodsurplus = getFormattedTruthyMultipleSelect(
    actor.food_waste,
    'food_surplus',
  );
  const targetb2b = getFormattedTruthyMultipleSelect(
    actor.target,
    'target_b2b',
  );
  const targetb2c = getFormattedTruthyMultipleSelect(
    actor.target,
    'target_b2c',
  );
  const target = flatten(targetb2b, targetb2c);

  return (
    <LayoutPage>
      <div className="stakeholder-page stakeholder">
        {actor && (
          <section className="cards-wrapper py-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h2 className="mb-1 mt-space">{actor.name}</h2>
                  <h5 className="color-c2 font-strong-m mt-2 pb-2">
                    {labelWrapper['actor'][language] + ' — '}
                    {actor.website && (
                      <a
                        href={addhttp(actor.website)}
                        target="_blank"
                        className="font-strong-m external color-purple"
                      >
                        {cleanUrlText(actor.website)}
                      </a>
                    )}
                  </h5>
                </div>
              </div>
            </div>
          </section>
        )}

        <section className="bg-white py-4 pb-5">
          {actor && (
            <div className="container-fluid card-indicator">
              <div className="row content">
                <div className="col-12 my-4 d-xl-none">
                  <div className="round-img" style={imgStyle}></div>
                </div>

                <div className="col-md-8 col-lg-12 col-xl-10 two-cols-lg">
                  <div className="break-inside-avoid">
                    <div className="font-sans-s color-black-50 mt-4">
                      {labelWrapper['description'][language]}
                    </div>
                    <div className="font-serif-m mt-1 mr-sm-5">
                      {language === 'en'
                        ? actor.description_en
                        : actor.description}
                    </div>
                  </div>

                  {interests.length > 0 && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['interests'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        {interests.map((interest, j) => {
                          const interestName =
                            labelWrapper['interestslist'][interest][language];
                          return (
                            <span key={j}>
                              {j > 0 ? ', ' : ''}
                              {interestName}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {products.length > 0 && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['products'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        {products.map((product, j) => {
                          console.log(product);

                          const productName = labelWrapper[product][language];
                          return (
                            <span key={j}>
                              {j > 0 ? ', ' : ''}
                              {productName}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {labelledProducts.length > 0 && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['labelled_products'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        {labelledProducts.map((label, j) => {
                          const productName = labelWrapper[label][language];
                          return (
                            <span key={j}>
                              {j > 0 ? ', ' : ''}
                              {productName}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {activities.length > 0 && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['activities'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        {activities.map((activity, j) => {
                          console.log(activity);
                          const activityName = labelWrapper[activity][language];
                          return (
                            <span key={j}>
                              {j > 0 ? ', ' : ''}
                              {activityName}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {actionsurplus.length > 0 && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['action_surplus'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        {actionsurplus.map((action, j) => {
                          const actionName = labelWrapper[action][language];
                          return (
                            <span key={j}>
                              {j > 0 ? ', ' : ''}
                              {actionName}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {foodsurplus.length > 0 && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['food_surplus'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        {foodsurplus.map((food, j) => {
                          const foodName = labelWrapper[food][language];
                          return (
                            <span key={j}>
                              {j > 0 ? ', ' : ''}
                              {foodName}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {target.length > 0 && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['target'][language]}
                      </div>

                      <div className="font-serif-m mt-1">
                        B2b : {actor.target.b2b ? 'true' : 'false'}
                      </div>

                      <div className="font-serif-m mt-1">
                        B2c : {actor.target.b2c ? 'true' : 'false'}
                      </div>

                      <div className="font-serif-m mt-1">
                        {target.map((t, j) => {
                          const targetName = labelWrapper[t][language];
                          return (
                            <span key={j}>
                              {j > 0 ? ', ' : ''}
                              {targetName}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {actor.contacts.length > 0 &&
                    (actor.contacts[0].contact_email ||
                      actor.contacts[0].contact_name ||
                      actor.contacts[0].contact_tel) && (
                      <div className="break-inside-avoid mr-xl-5 pt-1">
                        <div className="font-sans-s color-black-50 mt-4">
                          {labelWrapper['contacts'][language]}
                        </div>
                        <div className="font-serif-m mt-1">
                          {actor.contacts.map((contact, o) => {
                            return (
                              <div key={o}>
                                {contact.contact_email && (
                                  <div>{contact.contact_email}</div>
                                )}
                                {contact.contact_name && (
                                  <div>{contact.contact_name}</div>
                                )}
                                {contact.contact_tel && (
                                  <div>{contact.contact_tel}</div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                  {actor.location && actor.location.address && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['location'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        <div>{actor.location.address}</div>
                      </div>
                    </div>
                  )}

                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {labelWrapper['opening_hours'][language]}
                    </div>
                    {actor.opening_hours && actor.opening_hours.monday && (
                      <div className="font-serif-m mt-1">
                        {labelWrapper['monday'][language]} :{' '}
                        {actor.opening_hours.monday}
                      </div>
                    )}
                    {actor.opening_hours && actor.opening_hours.tuesday && (
                      <div className="font-serif-m mt-1">
                        {labelWrapper['tuesday'][language]} :{' '}
                        {actor.opening_hours.tuesday}
                      </div>
                    )}
                    {actor.opening_hours && actor.opening_hours.wednesday && (
                      <div className="font-serif-m mt-1">
                        {labelWrapper['wednesday'][language]} :{' '}
                        {actor.opening_hours.wednesday}
                      </div>
                    )}
                    {actor.opening_hours && actor.opening_hours.thursday && (
                      <div className="font-serif-m mt-1">
                        {labelWrapper['thursday'][language]} :{' '}
                        {actor.opening_hours.thursday}
                      </div>
                    )}
                    {actor.opening_hours && actor.opening_hours.friday && (
                      <div className="font-serif-m mt-1">
                        {labelWrapper['friday'][language]} :{' '}
                        {actor.opening_hours.friday}
                      </div>
                    )}
                    {actor.opening_hours && actor.opening_hours.saturday && (
                      <div className="font-serif-m mt-1">
                        {labelWrapper['saturday'][language]} :{' '}
                        {actor.opening_hours.saturday}
                      </div>
                    )}
                    {actor.opening_hours && actor.opening_hours.sunday && (
                      <div className="font-serif-m mt-1">
                        {labelWrapper['sunday'][language]} :{' '}
                        {actor.opening_hours.sunday}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-2 my-4 d-none d-xl-flex justify-content-end">
                  <div className="round-img" style={imgStyle}></div>
                </div>
              </div>
            </div>
          )}
        </section>

        {(actor.initiatives.length > 0 || actor.experts.length > 0) && (
          <section className="stakeholders-cards py-4 pb-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h2 className="mt-space-2 mb-space">
                    {labelWrapper['related'][language]}
                  </h2>
                </div>
              </div>
              <div className="row">
                {actor.initiatives.map((d, i) => {
                  return <InitiativeCard d={d} key={i} />;
                })}
                {actor.experts.map((d, i) => {
                  return <ExpertCard d={d} key={i} />;
                })}
              </div>
            </div>
          </section>
        )}
      </div>
    </LayoutPage>
  );
}

export default Actor;
