import React, { Fragment, Suspense, useState } from 'react';
import Layout from './Layout';
import {
  useStore,
  getFilteredIndicators,
  getGroupedIndicators,
  getIndicatorStakeholders,
} from '../store';
import {
  navigateScrollTop,
  labelWrapper,
  formatNumberWithCommas,
  getTranslation,
  validURL,
} from '../utils';
import { flatten } from 'lodash';
import { CSVLink, CSVDownload } from 'react-csv';
import Trend from '../components/Trend';
import TrendCompared from '../components/TrendCompared';
import RelatedVizExpanded from '../components/RelatedVizExpanded';
import IndicatorCard from '../components/IndicatorCard';
import ExpertCard from '../components/ExpertCard';
import InitiativeCard from '../components/InitiativeCard';
import ActorCard from '../components/ActorCard';

const RelatedCards = React.memo(({ indicator, group }) => {
  const language = useStore((state) => state.language);
  const indicatorsSubset = getFilteredIndicators(
    'impact_areas_id',
    indicator.impact_areas_id,
  );

  const related = group.length ? group : indicatorsSubset.slice(0, 6);
  const relatedWithoutCurrent =
    related && related.filter((e) => e.id != indicator.id);

  if (relatedWithoutCurrent.length == 0) {
    return false;
  }

  return (
    <section className="cards-wrapper pt-4 pb-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-space mt-space-2">
              {labelWrapper['related_indicators'][language]}
            </h2>
          </div>
        </div>
        <div className="row">
          {relatedWithoutCurrent &&
            relatedWithoutCurrent.slice(0, 6).map((d, j) => {
              return <IndicatorCard d={d} key={j} />;
            })}
        </div>
      </div>
    </section>
  );
});

const RelatedStakeholdersCards = React.memo(({ indicator }) => {
  const language = useStore((state) => state.language);
  const indicatorStakeholders = getIndicatorStakeholders(indicator);
  if (flatten(indicatorStakeholders.length) === 0) {
    return false;
  }

  return (
    <section className="stakeholders-cards pt-4 pb-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-space mt-space-2">
              {labelWrapper['related_stakeholders'][language]}
            </h2>
          </div>
        </div>
        <div className="row ">
          {flatten(indicatorStakeholders).map((d, i) => {
            if (d.finished !== undefined) {
              return <InitiativeCard d={d} key={i} />;
            }
            if (d.activities) {
              return <ActorCard d={d} key={i} />;
            }
            if (d.photo) {
              return <ExpertCard d={d} key={i} />;
            }
          })}
        </div>
      </div>
    </section>
  );
});

const IndicatorPage = ({ id }) => {
  const [hover, setHover] = useState(null);
  const language = useStore((state) => state.language);
  const indicators = useStore((state) => state.indicators);
  const getIndicator = useStore((state) => state.getIndicator);
  const indicator = getIndicator(Number(id));
  const data = indicator ? indicator[0] : {};
  const isLinked = data && data.linkeddataset ? true : false;
  const group =
    data && data.group_id ? getGroupedIndicators(data.id, data.group_id) : [];

  console.log(data);

  return (
    <Layout>
      <div className="Home">
        {data && (
          <section className="mt-space-2">
            <div className="container-fluid pt-1">
              <div className="row">
                <div className="col-12">
                  <div className="label l mb-1">
                    <a
                      className="d-inline-block pb-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateScrollTop(
                          `/indicators/indicator_category/${data.indicator_category}`,
                        );
                      }}
                    >{`${
                      language === 'nl'
                        ? data.indicator_category_nl
                        : data.indicator_category_en
                    }`}</a>
                    <span className="mx-1">›</span>
                    <a
                      className="d-inline-block pb-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateScrollTop(
                          `/indicators/impact_areas_id/${data.impact_areas_id}`,
                        );
                      }}
                    >{`${
                      language === 'nl'
                        ? data.impact_areas_nl
                        : data.impact_areas_en
                    }`}</a>
                  </div>
                  <h2 className="">
                    {language === 'nl' ? data.indicator_nl : data.indicator_en}
                  </h2>
                </div>
              </div>
            </div>
          </section>
        )}
        <section className="indicator-full">
          <div className="container-fluid">
            {data && (
              <div className="row content">
                <div className="col-md-3">
                  {isLinked ? (
                    <Fragment>
                      <p className="stat-text">
                        {formatNumberWithCommas(data.linkeddataset.count)}
                      </p>
                      <p className="stat-unit">{data.source} </p>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <p className="stat-text">
                        {hover
                          ? hover.data
                          : data.dataset !== null &&
                            data.dataset[data.dataset.length - 1]
                          ? formatNumberWithCommas(
                              data.dataset[data.dataset.length - 1].data,
                            )
                          : 'n/a'}
                      </p>
                      <p className="stat-unit">
                        {data.dataset && data.dataset[0]
                          ? getTranslation({
                              key: data.dataset[0].unit,
                              language: language,
                            })
                          : null}
                      </p>
                      <p className="stat-unit">
                        {hover
                          ? hover.year
                          : data.dataset &&
                            data.dataset[data.dataset.length - 1]
                          ? data.dataset[data.dataset.length - 1].year
                          : null}
                      </p>
                    </Fragment>
                  )}
                </div>

                {group.length ? (
                  <div className="col-md-9">
                    {data.visualisation_function === 'timeline' ? (
                      <div>
                        <TrendCompared
                          group={group}
                          w={(window.innerWidth * 0.75) / 6}
                          indicator={id}
                          setHover={setHover}
                          hover={hover}
                        />
                      </div>
                    ) : (
                      <RelatedVizExpanded
                        data={group}
                        id={data.id}
                        w={200}
                        setHover={setHover}
                        hover={hover}
                      />
                    )}

                    <div
                      style={{
                        height: '20px',
                        fontSize: '12px',
                        opacity: 0.5,
                        marginTop: '15px',
                        marginLeft: '30px',
                      }}
                    >
                      {data.visualisation_function === 'timeline' && hover && (
                        <span>
                          {language === 'en'
                            ? hover.indicator.indicator_en
                            : hover.indicator.indicator_nl}
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="col-md-9">
                    {data.visualisation_function === 'timeline' && (
                      <Trend
                        data={data.dataset}
                        w={350}
                        card={false}
                        setHover={setHover}
                        hover={hover}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="row content">
              <div className="col-12 py-5">
                <hr className="mt-space-2" />
              </div>
            </div>
            {data && (
              <div className="row content">
                <div className="col-md-12 two-cols-md">
                  {data.description_nl && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['description'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        {language === 'nl'
                          ? data.description_nl
                          : data.description_en}
                      </div>
                    </div>
                  )}

                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {labelWrapper['impact_areas'][language]}
                    </div>
                    <div className="font-serif-m mt-1">
                      {language === 'nl'
                        ? data.impact_areas_nl
                        : data.impact_areas_en}
                    </div>
                  </div>

                  {/* {data.overarching_objectives_nl && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">Overarching objectives</div>
                      <div className="font-serif-m mt-1">{data.overarching_objectives_nl}</div>
                    </div>
                  )} */}

                  {data.source && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['source'][language]}
                      </div>
                      {validURL(data.source) ? (
                        <div className="font-serif-m mt-1">
                          <a href={data.source}>{data.source}</a>
                        </div>
                      ) : (
                        <div className="font-serif-m mt-1">{data.source}</div>
                      )}
                    </div>
                  )}

                  {data.main_responsible && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['responsible'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        {data.main_responsible}
                      </div>
                    </div>
                  )}

                  {data.geographic_area && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['geographic_area'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        {labelWrapper[data.geographic_area][language]}
                      </div>
                    </div>
                  )}

                  {data && data.dataset && (
                    <div className="break-inside-avoid mr-xl-5 pt-1">
                      <div className="font-sans-s color-black-50 mt-4">
                        {labelWrapper['data'][language]}
                      </div>
                      <div className="font-serif-m mt-1">
                        <CSVLink
                          data={data.dataset}
                          filename={
                            language === 'nl'
                              ? data.indicator_nl
                              : data.indicator_en
                          }
                          className="btn-download"
                        >
                          Download &#x02193;
                        </CSVLink>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>

        {data && <RelatedStakeholdersCards indicator={data} />}

        {data && <RelatedCards indicator={data} group={group} />}
      </div>
    </Layout>
  );
};

export default IndicatorPage;
