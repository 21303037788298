import React, { useMemo } from 'react';
import { useStore } from '../store';
import { getFormattedTruthyMultipleSelect, labelWrapper } from '../utils';
import ReactMapboxGl, {
  Source,
  GeoJSONLayer,
  Feature,
  Layer,
  Popup,
  ZoomControl,
} from 'react-mapbox-gl';
import markerActorUrl from '../assets/images/marker-initiative.svg';
import markerInitiativeUrl from '../assets/images/marker-actor.svg';
import markerExpertUrl from '../assets/images/marker-expert.svg';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useState } from 'react';

const getDataSource = (items) => {
  return {
    type: 'FeatureCollection',
    features: items.map((d, i) => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [d.location.longitude, d.location.latitude],
        },
        properties: {
          type: d.type,
          name: d.name,
          interests: d.interests,
          id: d.id,
        },
      };
    }),
  };
};

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoibGV1dmVuMjAzMCIsImEiOiJja2lzanp2bHUyZ3dxMnZxanVmbGRpZHdiIn0.Yo8Z-NFO3SmlYek3W18KfQ',
  attributionControl: false,
  scrollZoom: false,
});

const GeoMap = React.memo(({ mapFilter }) => {
  const [selected, setSelected] = useState(null);
  const [center, setCenter] = useState([4.702338435631551, 50.877157351303836]);
  const experts = useStore((state) =>
    state.experts.filter(
      (d) => d.location && d.location.longitude && d.location.latitude,
    ),
  );
  const actors = useStore((state) =>
    state.actors.filter(
      (d) => d.location && d.location.longitude && d.location.latitude,
    ),
  );
  const initiatives = useStore((state) =>
    state.initiatives.filter(
      (d) => d.location && d.location.longitude && d.location.latitude,
    ),
  );

  const items = useMemo(() => {
    const thisItems = [];

    experts.forEach((d, i) => {
      if (mapFilter === null || mapFilter === 'experts') {
        thisItems.push({
          type: 'expert',
          location: d.location,
          name: d.name,
          interests: getFormattedTruthyMultipleSelect(d, 'interests'),
          id: d.id,
        });
      }
    });
    actors.forEach((d, i) => {
      if (mapFilter === null || mapFilter === 'actors') {
        thisItems.push({
          type: 'actor',
          location: d.location,
          name: d.name,
          interests: getFormattedTruthyMultipleSelect(d, 'interests'),
          id: d.id,
        });
      }
    });
    initiatives.forEach((d, i) => {
      if (mapFilter === null || mapFilter === 'initiatives') {
        thisItems.push({
          type: 'initiative',
          location: d.location,
          name: d.name,
          interests: getFormattedTruthyMultipleSelect(d, 'interests'),
          id: d.id,
        });
      }
    });

    return thisItems;
  }, [experts, initiatives, actors, mapFilter]);

  return (
    <Map
      style="mapbox://styles/leuven2030/ckj04vwo20leg19o4qyhisaia"
      className="geo-map-container"
      containerStyle={{
        height: '100%',
        width: '100%',
      }}
      center={center}
      zoom={[11.5]}
    >
      <ZoomControl
        // onControlClick="(map: object, zoomDiff: number) => void triggered when user clicks on minus or plus button"
        // style="object Style object merged with internal style into the container"
        className="zoom-control"
        // zoomDiff="number The shift number passed to the callback onControlClick"
        position="bottom-right"
      />

      {items.length && (
        <GeoJSONLayer
          data={getDataSource(items)}
          id="countries-source"
          circlePaint={{
            // "circle-color": "#ffffff",
            'circle-color': [
              'match',
              ['get', 'type'],
              'actor',
              '#5100ff',
              'initiative',
              '#ff9c50',
              'expert',
              '#ff6fa7',
              '#ccc',
            ],
            'circle-radius': 6,
            'circle-stroke-color': '#ffffff',
            'circle-stroke-opacity': 0.5,
          }}
          circleOnClick={(event) => {
            const selected = {
              coordinates: [event.lngLat.lng, event.lngLat.lat],
              properties: event.features[0].properties,
            };
            setSelected(selected);
            setCenter([event.lngLat.lng, event.lngLat.lat]);
          }}
        ></GeoJSONLayer>
      )}
      {selected && <MapPopup selected={selected} />}
    </Map>
  );
});

function MapPopup({ selected }) {
  const language = useStore((state) => state.language);
  return (
    <Popup key={selected.properties.id} coordinates={selected.coordinates}>
      <div className="card-stakeholder" style={{ width: '200px' }}>
        <div
          className={`font-sans-s font-700 color-${
            selected.properties.type === 'actor'
              ? 'c2'
              : selected.properties.type === 'initiative'
              ? 'c3'
              : 'c1'
          }`}
          style={{ textTransform: 'uppercase' }}
        >
          {labelWrapper[selected.properties.type][language]}
        </div>
        <div className="map-card-title">{selected.properties.name}</div>
        <a href={`${selected.properties.type}s/${selected.properties.id}`}>
          Open &#8599;
        </a>
      </div>
    </Popup>
  );
}

export default GeoMap;
