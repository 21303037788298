import React, { Fragment } from 'react';
import { useQuery } from 'react-query';
import { apiUrl } from '../settings';
import { labelWrapper } from '../utils';
import style from '../styles/Statistics.module.css';
import { useStore } from '../store';

const Statistics = () => {
  const language = useStore((state) => state.language);
  const { isLoading, error, data } = useQuery('statistics', () =>
    fetch(`${apiUrl}/statistics`).then((res) => res.json()),
  );

  if (isLoading) return 'Loading...';

  if (error) return 'An error has occurred: ' + error.message;

  return (
    <div className="statistics">
      {data.map((d, i) => {
        if (d.statistic) {
          return (
            <Fragment key={i}>
              {d.statistic.map((s, j) => {
                let cl = 'statistic';
                if (j == 2) {
                  cl += ' d-none d-sm-inline-flex';
                }
                if (j == 3) {
                  cl += ' d-none d-md-inline-flex';
                }
                if (j >= 4) {
                  return false;
                }
                // if (j == 2) { cl += " d-none d-md-inline-flex"; }
                // if (j == 3) { cl += " d-none d-md-inline-flex"; }
                // if (j == 4) { cl += " d-none d-md-inline-flex d-xl-none"; }
                // if (j >= 5) { return false; }
                return (
                  <div className={cl} key={j}>
                    <div>
                      <p className="value">{s.value}</p>
                      <p className="name">{labelWrapper[s.name][language]}</p>
                    </div>
                  </div>
                );
              })}
            </Fragment>
          );
        }
      })}
    </div>
  );
};

export default Statistics;
