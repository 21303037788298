import React, { Suspense } from 'react';
import { groupBy, shuffle } from 'lodash';
import Layout from './Layout';
import { useStore } from '../store';
import { labelWrapper, navigateScrollTop } from '../utils';
import { navigate } from '@reach/router';
import Loading from '../components/Loading';
import IndicatorCard from '../components/IndicatorCard';

const groupText = {
  social_sustainability: {
    en:
      'Social indicators are defined as statistical measures that describe social trends and conditions that affect human well-being',
    nl:
      'Sociale indicatoren worden gedefinieerd als statistische maatstaven die sociale trends en omstandigheden beschrijven die invloed hebben op het menselijk welzijn',
  },
  economic_sustainability: {
    nl:
      'Economische indicatoren omvatten statistieken over de economische activiteit in Leuven',
    en: 'Economic indicators include statistics on economic activity in Leuven',
  },
  urban_rural_integration: {
    nl:
      'Deze categorie omvat indicatoren met betrekking tot de relatie tussen stad en platteland',
    en: 'This category includes indicators related to urban-rural relationship',
  },
  environmental_sustainability: {
    nl:
      'Deze categorie omvat indicatoren met betrekking tot de staat van het milieu en de omgeving in Leuven',
    en:
      'This category includes indicators related to the state of the environment in Leuven',
  },
  food_governance: {
    nl:
      'Deze categorie omvat indicatoren met betrekking tot het beleid in Leuven',
    en: 'This category includes indicators related to the policy in Leuven',
  },
};

function Grouped({ grouped }) {
  const language = useStore((state) => state.language);

  return (
    <div key="grouped">
      {grouped.map((g, i) => {
        const indicatorsGroup = g;
        const categoryId = indicatorsGroup[0].indicator_category;
        const categoryName =
          language === 'nl'
            ? indicatorsGroup[0].indicator_category_nl
            : indicatorsGroup[0].indicator_category_en;
        const subCategories = g.map((v) => {
          return {
            name: language === 'nl' ? v.impact_areas_nl : v.impact_areas_en,
            id: v.impact_areas_id,
          };
        });
        const uniqSubcategories = subCategories.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
        );
        const uniqSubcategoriesBlocks = [];
        if (uniqSubcategories.length > 5) {
          const half = Math.ceil(uniqSubcategories.length / 2);
          uniqSubcategoriesBlocks.push(uniqSubcategories.slice(0, half));
          uniqSubcategoriesBlocks.push(uniqSubcategories.slice(half));
        } else {
          uniqSubcategoriesBlocks.push(uniqSubcategories);
        }

        console.log('categoryId', categoryId);

        return (
          <div className="container-fluid" id={categoryId} key={i}>
            <div className="row spacer">
              <div className="col-12 pb-5 mb-5">{/*<hr />*/}</div>
            </div>
            <div className="row" key={`category-${i}`}>
              <div className="col-md-6 col-xl-4">
                <div className="large-small-header-combo">
                  <h2>{categoryName}</h2>
                </div>
                <p className="font-serif-m pr-md-5">
                  {groupText[categoryId][language]}
                </p>
              </div>
              <div className="col-md-6 col-xl-8">
                <div className="large-small-header-combo">
                  <h5>{labelWrapper['categories'][language]}</h5>
                </div>
                <div className="row">
                  {uniqSubcategoriesBlocks.map((block, i) => (
                    <div
                      key={i}
                      className={
                        uniqSubcategoriesBlocks.length == 1
                          ? 'col-12'
                          : 'col-sm-6 col-md-12 col-xl-6'
                      }
                    >
                      {block.map((s, i) => (
                        <div key={i}>
                          <a
                            href=""
                            className="font-serif-m"
                            onClick={() => {
                              navigate(`/indicators/impact_areas_id/${s.id}`);
                            }}
                          >
                            {`${s.name}  ›`}
                          </a>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-space"></div>
            </div>
            <div className="row indicator-cards-container">
              {
                //shuffle(indicatorsGroup)
                indicatorsGroup
                  .filter((g) => {
                    return categoryId === 'social_sustainability'
                      ? g.geographic_area === 'municipality_leuven' ||
                          g.geographic_area === 'arrondissement_leuven'
                      : true;
                  })
                  .slice(0, 6)
                  .map((d, j) => {
                    return <IndicatorCard d={d} key={j} />;
                  })
              }
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <a
                  className="button l my-space-3"
                  onClick={() => {
                    navigateScrollTop(
                      `/indicators/indicator_category/${categoryId}`,
                    );
                  }}
                >
                  {labelWrapper['see_all'][language]}
                </a>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function Indicators({}) {
  const language = useStore((state) => state.language);
  const indicators = useStore((state) => state.indicators);
  const groupedBy = groupBy(indicators, 'indicator_category');
  const grouped = Object.values(groupedBy);
  const groupedKeys = Object.keys(groupedBy);

  return (
    <Layout>
      <div className="indicators">
        <section className="indicators-intro pad-small">
          <div className="container-fluid">
            <div className="row pb-5">
              <div className="col-12">
                <div className="large-small-header-combo">
                  <h2>{labelWrapper['indicators_dashboard'][language]}</h2>
                </div>
                <p className="font-serif-l">
                  {language === 'nl'
                    ? `Op deze pagina vindt u verschillende indicatoren die het voedselsysteem in Leuven beschrijven. De indicatoren zijn opgedeeld in vijf verschillende thema’s: Sociaal, Economisch, Relatie stad-platteland, Milieu en Beleid.`
                    : `On this page you will find several indicators that describe the food system in Leuven. The indicators are divided into five different themes: Social, Economic, Urban-Rural Integration, Environment and Policy.`}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="sub-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {grouped.map((g, i) => {
                  const indicatorsGroup = g;
                  const categoryId = indicatorsGroup[0].indicator_category;
                  const categoryName =
                    language === 'nl'
                      ? indicatorsGroup[0].indicator_category_nl
                      : indicatorsGroup[0].indicator_category_en;
                  return (
                    <a key={i} href={`#${categoryId}`}>
                      {categoryName}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </section>

        <section className="bg-purple py-2"></section>

        <section className="cards-wrapper">
          <Suspense fallback={<Loading />}>
            <Grouped grouped={grouped} />
          </Suspense>
        </section>
      </div>
    </Layout>
  );
}

export default Indicators;
