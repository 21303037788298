import React, { useState, useEffect } from 'react';
import { LinePath, AreaClosed } from '@visx/shape';
import { flatten } from 'lodash';
import { labelWrapper } from '../utils';
import { useStore } from '../store';
import { Bar } from '@visx/shape';
import { extent } from 'd3-array';
import { scaleTime, scaleLinear } from '@visx/scale';
import { AxisLeft, AxisBottom } from '@visx/axis';

const RelatedViz = ({ data, w = 200, id }) => {
  const svgWidth = 60;
  const svgHeight = 8;
  const margin = 0;
  const height = svgHeight - margin * 2;
  const width = svgWidth - margin * 2;
  const yScale = scaleTime({
    range: [0, height],
  });
  const xScale = scaleLinear({
    range: [0, width],
  });
  const yMax = width;
  const flattenDataset = flatten(data.map((d) => d.dataset));
  const language = useStore((state) => state.language);

  return (
    <div className="related-viz-wrapper">
      <div
        style={{ fontSize: '9px', opacity: 0.5 }}
      >{`${data.length} ${labelWrapper['related'][language]}`}</div>
      {data.map((g, j) => {
        //const dates = extent(flattenDataset, (d) => d.year);
        const bounds = extent(flattenDataset, (d) => d.data);
        xScale.domain([bounds[0] / 2, bounds[1]]);
        // yScale.domain([
        //   new Date(`01-01-$
        // {dates[0]}`),
        //   new Date(`01-01-${dates[1]}`),
        // ]);
        return (
          <svg height={svgHeight} width={svgWidth} key={`svg-${j}`}>
            <g transform={`translate(${0},${2})`}>
              {g.dataset.map((d, i) => {
                const barWidth = xScale(Number(d.data)) ?? 0;
                const barHeight = 5;
                const barX = 0;
                const barY = 0;
                return (
                  <g key={`bar-${i}`}>
                    <Bar
                      x={barX}
                      y={barY}
                      width={barWidth}
                      height={barHeight}
                      fill={id === g.id ? '#5100ff' : '#E9ECEF'}
                    />
                    {/* <text>{d.year}</text> */}
                  </g>
                );
              })}
            </g>
          </svg>
        );
      })}
    </div>
  );
};

export default RelatedViz;
