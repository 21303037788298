import React from 'react';
import { apiUrl } from '../settings';
import { getLogoIfPresent, labelWrapper, navigateScrollTop } from '../utils';
import noImg from '../assets/images/no-img.png';
import { useStore } from '../store';

const InitiativeCard = ({ d }) => {
  const language = useStore((state) => state.language);
  const maxWords = 22;
  const translated = language === 'en' ? d.description_en : d.description;
  const words = translated ? translated.split(' ') : [];
  let description;
  if (words.length == 0) {
    description = 'No description.';
  } else if (words.length <= maxWords) {
    description = translated;
  } else {
    description = words.splice(0, maxWords).join(' ') + '…';
  }
  const p = getLogoIfPresent(d);
  const imageUrl = p ? apiUrl + p : noImg;
  const imgStyle = { backgroundImage: 'url(' + imageUrl + ')' };

  return (
    <div className="col-md-6 col-xl-4 card-stakeholder-col">
      <div
        className="card-stakeholder"
        onClick={() => navigateScrollTop(`/initiatives/${d.id}`)}
      >
        <header>
          <div className="left">
            <div className="font-sans-s font-700 color-c3">
              {labelWrapper['initiative'][language].toUpperCase()}
            </div>
            <div className="card-title">{d.name}</div>
            <div className="font-sans-s color-blackish-80"></div>
          </div>
          <div className="right">
            <div className="round-img thumb" style={imgStyle}></div>
          </div>
        </header>

        <div className="card-body">
          <div className="font-serif-m font-400">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default InitiativeCard;
