import { Link } from '@reach/router';
import { closeMenuMobile } from '../utils';

const LinkScrollTop = (props) => {
  return (
    <Link 
      onClick={() => { 
				closeMenuMobile();
      	window.scrollTo(0, 0); 
      }}
      {...props}
    >
      {props.children}
    </Link>
  );
};

export default LinkScrollTop;
