import React, { useState } from 'react';
import { LinePath, AreaClosed } from '@visx/shape';
import { extent } from 'd3-array';
import * as allCurves from '@visx/curve';
import { scaleTime, scaleLinear } from '@visx/scale';
import { AxisLeft, AxisBottom } from '@visx/axis';

const Trend = ({ card = true, data, w = 200, setHover, hover }) => {
  const dates = extent(data, (d) => d.year);
  const bounds = extent(data, (d) => d.data);
  const svgWidth = w;
  const svgHeight = w / (card ? 1.55 : 2);
  const marginTop = 10;
  const marginLeft = card ? 15 : 30;
  const height = svgHeight - marginTop * 2 - 10;
  const width = svgWidth - marginLeft * 2;
  const dLast = data && data.length && data[data.length - 1];

  const xScale = scaleTime({
    range: [0, width],
  });
  const yScale = scaleLinear({
    range: [height, 0],
  });

  const yMax = height;
  xScale.domain([new Date(`01/01/${dates[0]}`), new Date(`01/01/${dates[1]}`)]);
  yScale.domain([0, bounds[1]]);

  return (
    <div>
      <svg height={svgHeight} width={svgWidth}>
        <g transform={`translate(${marginLeft},${marginTop})`}>
          {data && (
            <AreaClosed
              curve={allCurves['curveLinear']}
              data={data}
              x={(d) => {
                return xScale(new Date(d.year));
              }}
              y={(d) => {
                return yScale(Number(d.data)) ?? yMax;
              }}
              yScale={yScale}
              shapeRendering="geometricPrecision"
              fill="#E9ECEF"
            />
          )}
          {data && (
            <LinePath
              curve={allCurves['curveLinear']}
              data={data}
              x={(d) => {
                return xScale(new Date(d.year));
              }}
              y={(d) => {
                return yScale(Number(d.data)) ?? yMax;
              }}
              stroke="#4725F5"
              strokeWidth={2}
              strokeOpacity={1}
              shapeRendering="geometricPrecision"
            />
          )}
          {!card &&
            data.map((d, j) => {
              const isLast = j === data.length - 1;
              const isHovered = d === hover;
              return (
                <circle key={j}
                  cx={xScale(new Date(d.year))}
                  cy={yScale(Number(d.data)) ?? yMax}
                  r="4"
                  fill={isHovered ? 'black' : isLast ? '#4725F5' : 'white'}
                  stroke={
                    isHovered
                      ? 'transparent'
                      : !isLast
                      ? '#4725F5'
                      : 'transparent'
                  }
                  strokeWidth={2}
                  onMouseEnter={() => setHover(d)}
                  onMouseLeave={() => setHover(null)}
                  style={{
                    cursor: 'pointer',
                  }}
                />
              );
            })}
          {card && (
            <circle
              cx={xScale(new Date(dLast.year))}
              cy={yScale(Number(dLast.data)) ?? yMax}
              r="4"
              fill="#4725F5"
            />
          )}
        </g>
        {!card && (
          <g
            transform={`translate(${svgWidth - marginLeft}, ${marginTop})`}
            style={{ pointerEvents: 'none' }}
          >
            <AxisLeft
              top={0}
              left={0}
              scale={yScale}
              numTicks={2}
              hideAxisLine={true}
              hideTicks={false}
              label=""
              stroke="#1b1a1e"
              tickLabelProps={(value, index) => ({
                fill: 'rgba(0,0,0,.5)',
                textAnchor: 'end',
                fontSize: '10px',
                dx: '-2px',
                dy: '2px',
              })}
              tickComponent={({ formattedValue, ...tickProps }) => (
                <text {...tickProps}>{formattedValue}</text>
              )}
              tickLength={width}
              tickStroke={'rgba(0,0,0,.3)'}
              tickClassName={'tickTrend'}
            />
          </g>
        )}
        <g
          transform={`translate(${marginLeft}, ${0})`}
          style={{ pointerEvents: 'none' }}
        >
          <AxisBottom
            top={0}
            left={0}
            scale={xScale}
            numTicks={card ? 1 : data.length - 1}
            tickStroke={'rgba(0,0,0,.3)'}
            tickClassName={'tickTrend'}
            hideAxisLine={true}
            hideTicks={card ? true : false}
            tickLength={height + 10}
            tickComponent={({ formattedValue, ...tickProps }) => (
              <text {...tickProps}>{formattedValue}</text>
            )}
            tickLabelProps={(value, index) => ({
              fill: 'rgba(0,0,0,.5)',
              textAnchor: 'middle',
              fontSize: '10px',
              dx: '0px',
              dy: '2px',
            })}
          ></AxisBottom>
        </g>
        )}
      </svg>
    </div>
  );
};

export default Trend;
