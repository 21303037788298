import React from 'react';
import Layout from './Layout';
import { useStore } from '../store';
import ActorCard from '../components/ActorCard';
import { labelWrapper, navigateScrollTop } from '../utils';
import { useState } from 'react';
import searchIcon from '../assets/images/search.svg';

function Actors({}) {
  const language = useStore((state) => state.language);
  const [interestSelected, setInterestSelected] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const actors = useStore((state) => state.actors);
  const interestsStats = {};
  const exclude = ['id'];
  actors.forEach((e) => {
    if (e && e.interests) {
      Object.keys(e.interests).forEach((interest) => {
        if (!exclude.includes(interest)) {
          if (!interestsStats.hasOwnProperty(interest)) {
            interestsStats[interest] = {
              count: 0,
              name_nl: labelWrapper.interestslist[interest]['nl'],
              name_en: labelWrapper.interestslist[interest]['en'],
            };
          }
          if (e.interests[interest] == true) {
            interestsStats[interest].count++;
          }
        }
      });
    }
  });

  const updateFilters = (type, value) => {
    if (type === null) {
      setInterestSelected(null);
      setSearchText('');
      setSearchIsOpen(false);
    } else if (type == 'search') {
      setSearchText(value);
      setInterestSelected(null);
    } else if (type == 'interest') {
      setSearchText('');
      setInterestSelected(value);
      if (searchIsOpen) {
        setSearchIsOpen(false);
      }
      document.getElementById('list-title-anchor').scrollIntoView();
    }
  };

  const filteredActors = actors
    .filter((d, i) => {
      if (interestSelected === null) {
        return true;
      }
      console.log(d.interests);
      return (
        d.interests &&
        d.interests.hasOwnProperty(interestSelected) &&
        d.interests[interestSelected]
      );
    })
    .filter((d, i) => {
      if (searchText.trim() === '') {
        return true;
      }
      return (
        d.name.toLowerCase().indexOf(searchText.toLowerCase().trim()) !== -1
      );
    })
    .sort((a, b) => {
      if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
      if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
      return 0;
    });

  return (
    <Layout>
      <section className="indicators-intro pad-small">
        <div className="container-fluid">
          <div className="row pb-5">
            <div className="col-12">
              <div className="large-small-header-combo">
                <h2>{labelWrapper['actors'][language]}</h2>
              </div>
              {language === 'nl' ? (
                <p className="font-serif-l">
                  Deze pagina omvat alle actoren in het Leuvense voedselsysteem,
                  van boer tot bord. Is jouw bedrijf of organisatie een acteur
                  in het Leuvense voedselsysteem en zou je graag in de
                  onderstaande lijst weergegeven worden? Voeg dan{' '}
                  <a
                    className="hover-white u"
                    onClick={() => {
                      navigateScrollTop(`/form`);
                    }}
                  >
                    hier
                  </a>{' '}
                  je informatie toe.
                </p>
              ) : (
                <p className="font-serif-l">
                  This page includes all actors in the Leuven food system, from
                  farm to fork. Is your company or organization an actor in the
                  Leuven food system and would you like to be displayed in the
                  list below? Then add your information{' '}
                  <a
                    className="hover-white u"
                    onClick={() => {
                      navigateScrollTop(`/form`);
                    }}
                  >
                    here
                  </a>
                  .{' '}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="sub-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {Object.keys(interestsStats).map((k, i) => {
                const interestId = k;
                const interestName =
                  language === 'nl'
                    ? interestsStats[k].name_nl
                    : interestsStats[k].name_en;
                return (
                  <a
                    key={i}
                    onClick={() => updateFilters('interest', interestId)}
                  >
                    {interestName}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-purple py-2"></section>

      <a id="list-title-anchor"></a>

      <section className="stakeholders-cards pad">
        <div className="container-fluid pad-small-b">
          <div className="row">
            <div className="col-12">
              {searchIsOpen ? (
                <div className="title-and-search open">
                  <div className="input-wrapper mr-2">
                    <img src={searchIcon} className="mr-2" />
                    <input
                      type="text"
                      placeholder="actor name"
                      className="font-strong-ll"
                      onChange={(e) => {
                        updateFilters('search', e.target.value);
                      }}
                      autoFocus
                    />
                  </div>
                  <a
                    onClick={() => {
                      updateFilters(null);
                    }}
                  >
                    <span className="font-strong-ll">&times;</span>
                  </a>
                </div>
              ) : interestSelected ? (
                <h2 className="mb-2 pb-1">
                  <a className="" onClick={() => updateFilters(null)}>
                    {labelWrapper['all_actors'][language]}
                  </a>
                  {`  ›  ${labelWrapper.interestslist[interestSelected][language]}`}
                  <a
                    className="hover-purple ml-2"
                    onClick={() => updateFilters(null)}
                  >
                    &times;
                  </a>
                </h2>
              ) : (
                <div className="title-and-search">
                  <h2 className="mb-0">
                    {labelWrapper['all_actors'][language]}
                  </h2>
                  <a
                    className="open-search-link"
                    onClick={() => setSearchIsOpen(true)}
                  >
                    <img src={searchIcon} />
                    <span className="d-none d-md-inline ml-2">
                      {labelWrapper['search_actors'][language]}
                    </span>
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            {filteredActors.map((d, i) => {
              if (d) {
                return <ActorCard d={d} key={`actor-${i}`} />;
              }
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Actors;
