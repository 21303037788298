import React from 'react';
import { useStore, getGroupedIndicators } from '../store';
import { navigateScrollTop, formatNumberWithCommas } from '../utils';
import ReactMarkdown from 'react-markdown';

const RandomSentence = ({ seed }) => {
  const language = useStore((state) => state.language);
  const indicators = useStore((state) => state.indicators);
  const getIndicator = useStore((state) => state.getIndicator);
  const sentences = [
    [
      {
        indicator: null,
        en: 'Leuven counts',
        nl: 'Leuven telt',
      },
      {
        indicator: 36,
        en: 'XXX 100% vegetarian restaurants',
        nl: 'XXX vegetarische restaurants',
      },
      {
        indicator: null,
        en: 'and',
        nl: 'en',
      },
      {
        indicator: 37,
        en: 'XXX 100% plant-based restaurants',
        nl: 'XXX restaurants met een 100% plantaardig aanbod',
      },
    ],
    [
      {
        indicator: null,
        en: 'Leuven counts',
        nl: 'Leuven telt',
      },
      {
        indicator: 84,
        en: 'XXX packaging-free shops',
        nl: 'XXX verpakkingsvrije winkels',
      },
      {
        indicator: null,
        en: 'and',
        nl: 'en',
      },
      {
        indicator: 25,
        en: 'XXX community vegetable gardens',
        nl: 'XXX buurtmoestuinen',
      },
    ],
    [
      {
        indicator: null,
        en: 'Leuven counts',
        nl: 'Leuven telt',
      },
      {
        indicator: 58,
        en: 'XXX organic farms',
        nl: 'XXX biologische landbouwbedrijven',
      },
      {
        indicator: null,
        en: 'and',
        nl: 'en',
      },
      {
        indicator: 34,
        en: 'XXX farms that sell directly',
        nl: 'XXX boerderijen',
      },
      {
        indicator: null,
        en: 'to customers through Community-Supported Agriculture',
        nl:
          'die rechtstreeks aan de consument verkopen via gemeenschapslandbouw',
      },
    ],
    [
      {
        indicator: null,
        en: 'Leuven counts',
        nl: 'Leuven telt',
      },
      {
        indicator: 32,
        en: 'XXX short food supply',
        nl: 'XXX korte keten',
      },
      {
        indicator: null,
        en: 'chain initiatives and',
        nl: 'initiatieven en',
      },
      {
        indicator: 51,
        en: 'XXX weekly markets',
        nl: 'XXX wekelijkse markten',
      },
    ],
  ];
  const index = Math.floor((seed * 100) % sentences.length);
  const parts = sentences[index];

  return (
    <div className="random-sentence">
      {parts.map((d, i) => {
        if (d.indicator !== null) {
          const id = d.indicator;
          const indicator = getIndicator(Number(id));
          const data = indicator ? indicator[0] : {};
          console.log(data);
          let n = '~';

          if (!indicator || !data) {
            n = '…';
          } else if (!data.linkeddataset && !data.dataset) {
            n = '_';
          } else {
            const isLinked = data && data.linkeddataset ? true : false;
            const group =
              data && data.group_id
                ? getGroupedIndicators(data.id, data.group_id)
                : [];
            n = isLinked
              ? formatNumberWithCommas(data.linkeddataset.count)
              : data.dataset && data.dataset[data.dataset.length - 1]
              ? formatNumberWithCommas(
                  data.dataset[data.dataset.length - 1].data,
                )
              : 'n/a';

            // alert(number);
          }
          const text = d[language].replace('XXX', n);
          return (
            <span className="color-purple" key={i}>
              <a
                onClick={(e) => {
                  navigateScrollTop(`/indicators/${id}`);
                }}
              >
                {text}
              </a>{' '}
            </span>
          );
        } else {
          return (
            <span key={i} className="">
              {d[language]}{' '}
            </span>
          );
        }
      })}
    </div>
  );
};
// const RandomSentence = ({ seed }) => {

//   const language = useStore((state) => state.language);
//   const indicators = useStore((state) => state.indicators);
//   const getIndicator = useStore((state) => state.getIndicator);
//   const sentences = [
//     {
//       en: "Leuven counts {ID=36} _100% vegetarian_ restaurants and {ID=37} _100% plant-based_ restaurants",
//       nl: "Leuven telt {ID=36} _vegetarische_ restaurants en {ID=37} _restaurants_ met een 100% plantaardig aanbod",
//     }, {
//       en: "Leuven counts {ID=84} _packaging-free_ shops and {ID=25} _community_ vegetable gardens",
//       nl: "Leuven telt {ID=84} _verpakkingsvrije_ winkels en {ID=25} _buurtmoestuinen_",
//     }, {
//       en: "Leuven counts {ID=42} _organic farms_ and {ID=34} _farms that sell directly_ to customers through Community-Supported Agriculture",
//       nl: "Leuven telt {ID=42} _biologische_ landbouwbedrijven en {ID=34} _boerderijen_ die rechtstreeks aan de consument verkopen via gemeenschapslandbouw",
//     }, {
//       en: "Leuven counts {ID=32} _short food supply_ chain initiatives and {ID=51} _weekly markets_",
//       nl: "Leuven telt {ID=32} _korte keten_ initiatieven en {ID=51} _wekelijkse markten_",
//     }
//   ];
//   const index = Math.floor((seed * 100) % sentences.length);
//   const string = sentences[index][language];
//   const splits = string.split(/({ID=\d+)}/);

//   return (
//     <div className="random-sentence">
//       { splits.map((s, i) => {
//           if (s.substr(0, 4) === "{ID=") {
//             const id = s.substr(4);
//             const indicator = getIndicator(Number(id));
//             const data = indicator ? indicator[0] : {};
//             console.log(data);

//             if (!indicator || !data) {
//               return <span className="color-purple" key={i}>{"…"}</span>
//             } else if (!data.linkeddataset && !data.dataset) {
//               return <span className="color-purple" key={i}>{"_"}</span>
//             } else {

//               const isLinked = data && data.linkeddataset ? true : false;
//               const group = data && data.group_id ? getGroupedIndicators(data.id, data.group_id) : [];
//               const number = isLinked
//                 ? formatNumberWithCommas(data.linkeddataset.count)
//                 : data.dataset && data.dataset[data.dataset.length - 1]
//                   ? formatNumberWithCommas(data.dataset[data.dataset.length - 1].data)
//                   : 'n/a';

//               // alert(number);

//               return (
//                 <span className="color-purple" key={i}>
//                   {" "}<a onClick={(e) => {navigateScrollTop(`/indicators/${id}`);}}>{number}</a>{" "}
//                 </span>
//               );
//             }
//           } else {
//             return (
//               <ReactMarkdown key={i} className="markdown-output">{s}</ReactMarkdown>
//             );
//           }
//       })}
//     </div>
//   );
// };

export default RandomSentence;
